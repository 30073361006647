import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface Props {
  isMobile: boolean
  openMenu: boolean
  openHubMenu: boolean

  curSolution: string
}

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isMobile: false,
    openMenu: false,
    openHubMenu: false,

    curSolution: ''
  } as Props,
  reducers: {
    setIsMobile: (state, { payload } : PayloadAction<boolean>) => {
      state.isMobile = payload
    },
    setOpenMenu: (state, { payload } : PayloadAction<boolean>) => {
      state.openMenu = payload
    },
    setOpenHubMenu: (state, { payload } : PayloadAction<boolean>) => {
      state.openHubMenu = payload
    },
    setCurSolution: (state, { payload } : PayloadAction<string>) => {
      state.curSolution = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setIsMobile, setOpenMenu, setCurSolution, setOpenHubMenu } = appSlice.actions

export default appSlice.reducer
