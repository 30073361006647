import StyledContainer from "style/styledContainer"
import styled, { css } from "styled-components"
import { cubicBezier } from "framer-motion"

import { ReactComponent as ArrowDownSvg } from 'assets/svgs/icn_arrow_down.svg'

import { ReactComponent as NavIcon1Svg } from 'assets/svgs/nav_icon_1.svg'
import { ReactComponent as NavIcon2Svg } from 'assets/svgs/nav_icon_2.svg'
import { ReactComponent as NavIcon3Svg } from 'assets/svgs/nav_icon_3.svg'
import { ReactComponent as NavIcon4Svg } from 'assets/svgs/nav_icon_4.svg'
import { ReactComponent as NavIcon5Svg } from 'assets/svgs/nav_icon_5.svg'
import { ReactComponent as NavIcon6Svg } from 'assets/svgs/nav_icon_6.svg'
import { ReactComponent as NavIcon7Svg } from 'assets/svgs/nav_icon_7.svg'
import { ReactComponent as NavIcon8Svg } from 'assets/svgs/nav_icon_8.svg'
import { ReactComponent as NavIcon9Svg } from 'assets/svgs/nav_icon_9.svg'
import { ReactComponent as NavIcon10Svg } from 'assets/svgs/nav_icon_10.svg'
import { ReactComponent as NavIcon11Svg } from 'assets/svgs/nav_icon_11.svg'
import { ReactComponent as NavIcon12Svg } from 'assets/svgs/nav_icon_12.svg'
import { ReactComponent as NavIcon13Svg } from 'assets/svgs/nav_icon_13.svg'
import { ReactComponent as NavIcon14Svg } from 'assets/svgs/nav_icon_14.svg'
import { ReactComponent as NavIcon15Svg } from 'assets/svgs/nav_icon_15.svg'

import { ReactComponent as NavIcon16Svg } from 'assets/svgs/nav_icon_16.svg'
import { ReactComponent as NavIcon17Svg } from 'assets/svgs/nav_icon_17.svg'
import { ReactComponent as NavIcon18Svg } from 'assets/svgs/nav_icon_18.svg'
import { ReactComponent as NavIcon19Svg } from 'assets/svgs/nav_icon_19.svg'



import { Column, Row } from "style/preset"
import { motion } from "framer-motion"
import IconCom from "components/IconCom"
import { useCallback, useState } from "react"
import { mediaMax1024 } from "pages/Home/components/CommonStyle"
import useUnits from "hooks/useUnits"
import { useAppDispatch } from "hooks/redux/hooks"
import { setCurSolution } from "hooks/redux/slices/appSlice"

export const menuList = [
    {
        label: 'Solutions',
        child: [
            {
                svgIcon: NavIcon1Svg,
                label: 'Trading',
                des: 'Seamlessly trade across 55 chains'
            },
            {
                svgIcon: NavIcon2Svg,
                label: 'Wallet',
                des: 'Diversified operations and self-custody'
            },
            {
                svgIcon: NavIcon3Svg,
                label: 'Coin blinks',
                des: 'Staying ahead in crypto trends'
            },
            {
                svgIcon: NavIcon4Svg,
                label: 'Natural Language',
                des: 'Simplify crypto through conversing'
            },
            {
                svgIcon: NavIcon5Svg,
                label: 'Hardware wallet',
                des: 'Top-of-the-line cold storage solution'
            },
            {
                svgIcon: NavIcon6Svg,
                label: 'Credit Card',
                des: 'Online shopping made easy and quick'
            },
            {
                svgIcon: NavIcon7Svg,
                label: 'Earn with Chainge',
                des: 'Daily gains through passive income'
            },
        ],
        split: 4,
    },
    {
        label: 'Ecosystem',
        child: [
            {
                svgIcon: NavIcon8Svg,
                label: 'Networks',
                des: 'Explore our 55+ supported networks'
            },
            {
                svgIcon: NavIcon9Svg,
                label: 'DEXs',
                des: 'Browse our aggregated DEXs list'
            },
            {
                svgIcon: NavIcon10Svg,
                label: 'Aggregators',
                des: 'Cross-chain routing through the best'
            },
            {
                svgIcon: NavIcon11Svg,
                label: 'Web3 browsers',
                des: 'Navigating the decentralized web'
            },
            {
                svgIcon: NavIcon12Svg,
                label: 'Wallets',
                des: 'Connect them to our web dApp'
            },
            {
                svgIcon: NavIcon13Svg,
                label: 'Become a partner',
                des: 'Join us and become a valued partner'
            },
        ],
        split: 3
    },
    {
        label: 'Developers',
        child: []
    },
    {
        label: 'Docs',
        child: [
            {
                svgIcon: NavIcon16Svg,
                label: 'Tech',
                des: `Secure interoperable transactions with Fusion's DCRM`,
                url: 'https://www.fusion.org/tech/dcrm'
            },
            {
                svgIcon: NavIcon17Svg,
                label: 'Router',
                des: `Enhance trading with the most advanced cross-chain router`,
                url: 'https://www.cryptoeq.io/research/cross-chain-liquidity'
            },
            {
                svgIcon: NavIcon18Svg,
                label: 'Analysis',
                des: `Explore Chainge's DEX liquidity aggregator`,
                url: 'https://www.blocmates.com/protocol-focus/chainge-finance-the-future-of-decentralized-exchanges'
            },
            {
                svgIcon: NavIcon19Svg,
                label: 'Github',
                des: `Discover our technology through our GitHub repository`,
                url: 'https://github.com/ChaingeFinance'
            },
        ],
        split: 4,
    },
    {
        label: 'Governance',
        child: [
            {
                svgIcon: NavIcon14Svg,
                label: 'DAO',
                des: 'Decentralized governance for decision-making.',
                soon: true
            },
            {
                svgIcon: NavIcon15Svg,
                label: 'Tokenomics 2.0',
                des: 'Empowering users within the Chainge ecosystem.',
                soon: true
            },
        ],
        split: 2
    },
    {
        label: 'Markets',
        child: [],
        soon: true
    },
]



const NavBarCom = () => {
    const [focus, setFocus] = useState(false)
    const dispatch = useAppDispatch()
    const { scrollTopByFlag, openBrowserByUrl } = useUnits()

    const fnSubNavClick = useCallback((subItem: any, item: any) => {
        if(item.label === 'Solutions' && subItem) {
            dispatch(setCurSolution(subItem.label))
            scrollTopByFlag('solution_flag')
        }
        if(item.label === 'Ecosystem' && subItem) {
            scrollTopByFlag('ecosystem_flag')
        }
        if(item.label === 'Developers') {
            scrollTopByFlag('developers_flag')
        }

        if(subItem && subItem?.url) {
            openBrowserByUrl(subItem.url)
        }
    }, [dispatch, scrollTopByFlag, openBrowserByUrl])

    return <NavBarWrap>
        {
            menuList.map((item, index) => <AnimateNavItemWrap onClick={() => fnSubNavClick(null, item)} onMouseOver={() => setFocus(true)} onMouseLeave={() => setFocus(false)} key={index} $soon={!!item.soon} $active={!!!item.child.length && !!!item.soon}
                initial={{ top: '-16px', opacity: 0 }}
                animate={{ top: '0px', opacity: 1 }}
                transition={{
                    duration: 0.4,
                    delay: 1.4 + 0.2 * index,
                    ease: cubicBezier(0.37, 0.08, 0.49, 1.1)
                }}
            >
                    <StyledContainer className="menu_label">{item.label}</StyledContainer>
                    {
                        !!item.child.length && !item.soon && <ArrowDownSvgWrap></ArrowDownSvgWrap>
                    }
                    {
                        item.soon && <SoonTip>Soon</SoonTip>
                    }
                    {
                        !!item.child.length && focus && <SubMenuOutBoxWrap>
                            <SubMenuBoxWrap $flex>
                                <StyledContainer>
                                    {
                                        item.child.slice(0, item.split).map((subItem:any, index) => <AnimateWrap  key={index} 
                                            initial={{opacity: 0, transform: 'translateX(5px)'}}
                                            animate={{opacity: 1, transform: 'translateX(0px)'}}
                                            transition={{
                                                duration: 0.2,
                                                delay: 0.1 * index
                                            }}
                                        > 
                                                <SubNavCom onClick={() => fnSubNavClick(subItem, item)} otherProps={{"$mb":'20px'}} svg={subItem.svgIcon} label={subItem.label} des={subItem.des} soon={subItem.soon}></SubNavCom>
                                            </AnimateWrap>
                                        )
                                    }
                                </StyledContainer>
                                {
                                    !!item.child.slice(item.split).length && <StyledContainer $width="50px"></StyledContainer>
                                }
                                <StyledContainer>
                                    {
                                        item.child.slice(item.split).map((subItem:any, index) => <AnimateWrap  key={index} 
                                                initial={{opacity: 0, transform: 'translateX(5px)'}}
                                                animate={{opacity: 1, transform: 'translateX(0px)'}}
                                                transition={{
                                                    duration: 0.2,
                                                    delay: 0.1 * index + ((item.split || 0) * 0.1)
                                                }}
                                            >
                                                <SubNavCom onClick={() => fnSubNavClick(subItem, item)} otherProps={{"$mb":'20px'}} key={index} svg={subItem.svgIcon} label={subItem.label} des={subItem.des} soon={subItem.soon}></SubNavCom>
                                            </AnimateWrap>
                                        )
                                    }
                                </StyledContainer>
                            </SubMenuBoxWrap>
                        </SubMenuOutBoxWrap>
                        
                    }
                </AnimateNavItemWrap>
            )
        }
    </NavBarWrap>
}

interface SubNavProps {
    svg: any
    label: string
    des: string
    otherProps?: any
    soon?: boolean
    onClick?: () => void
}
const SubNavCom = ({svg, label, des, otherProps, soon = false, onClick = () => {}}: SubNavProps) => {
    return <SubNavWrap {...otherProps} onClick={onClick}>
        <IconComWrap Svg={svg}></IconComWrap>
        <Column $ml="16px" $flex1="1">
            <Row>
                <StyledContainer $fontSize="16px" $lineHeight="1" $fontWeight6 $roobertSemiBold>{label}</StyledContainer>
                {
                    soon && <SoonTip>Soon</SoonTip>
                }
            </Row>
            <StyledContainer $mt="6px" $fontSize="14px" $lineHeight="16px" $fontWeight4 $roobertRegular>{des}</StyledContainer>
        </Column>
    </SubNavWrap>
}

const NavBarWrap = styled(StyledContainer)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    ${
        mediaMax1024(`
            display: none !important;
        `)
    }
`

const ArrowDownSvgWrap = styled(ArrowDownSvg)`
    margin-left: 10px;
`

export const SoonTip = styled(StyledContainer)`
    padding: 4px 6px;
    line-height: 1 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: RoobertSemiBold;
    color: #8A8E93 !important;
    background-color: #3D3D3D;
    border-radius: 12px;
    margin-left: 6px;
`

const NavItemWrap = styled(Row)`
    padding: 12px;
    cursor: pointer;
    margin-right: 10px;
    position: relative;
    border-radius: 8px;

    &>div.menu_label {
        font-size: 16px;
        line-height: 1;
        color: #DFE0E2;
        font-weight: 500;
    }

    &:last-child {
        margin-right: 0;
    }

    &::after {
        content: '';
        display: block;
        width: 0px;
        height: 0px;
        border-radius: 50%;
        background-color: #FFFFFF;
        
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 0px;
        transition: all linear 0.2s;
    }
`

const SubMenuOutBoxWrap = styled(StyledContainer)`
    position: absolute;
    top: 0;
    left: 0;
    display: none;
`
const SubMenuBoxWrap = styled(StyledContainer)`
    margin-top: 50px;
    padding: 40px;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-radius: 9px;
    background-color: #1F1F1F;
    white-space: nowrap;
`

const AnimateNavItemWrap = styled(motion(NavItemWrap))<{$soon: boolean, $active?: boolean}>`
    ${
        ({$soon}) => $soon ? css`
            cursor: default;
            &>div.menu_label {
                color: #525252 !important;
            } 
            background-color: transparent !important;
        `:css`
            
        `
    }

    &:hover {
        &>div.menu_label {
            color: #ffffff;
        }

        ${SubMenuOutBoxWrap} {
            display: block;
        }
    }

    ${
        ({$active}) => $active ? css`
            &:hover {
                &::after {
                    content: '';
                    width: 4px;
                    height: 4px;
                }
            }
        `: css`
            &:hover {
                background-color: #1F1F1F;
            }
        `
    }

`;


const IconComWrap = styled(IconCom)`
    width: 50px;
    height: 50px;
    circle, path, rect {
        transition: all linear 0.2s;
    }
`

const SubNavWrap = styled(Row)`
&>div>div  {
        &:nth-child(1) {
            color: #F2F2F2;
        }
        &:nth-child(2) {
            color: #767A7F;
        }
    }

    &:hover {
        &>div>div {
            &:nth-child(1) {
                color: #ffffff;
            }
            &:nth-child(2) {
                color: #ffffff;
            }
        }

        ${IconComWrap} {
            circle {
                fill: #4D1DF7;
            }
            path, rect {
                fill: #ffffff;
            }
        }
    }
`

const AnimateWrap = styled(motion.div)``

export default NavBarCom