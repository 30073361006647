import { motion } from "framer-motion"
import { useState } from "react"
import { Parallax } from "react-scroll-parallax"
import { Column, Row } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled from "styled-components"

import card1Img from 'assets/images/scroll_card_1.png'
import card2Img from 'assets/images/scroll_card_2.png'
import card3Img from 'assets/images/scroll_card_3.png'
import card4Img from 'assets/images/scroll_card_4.png'
import card5Img from 'assets/images/scroll_card_5.png'

import { ReactComponent as ArrowRightSvg } from 'assets/svgs/arrow_right.svg'
import { mediaMax1024 } from "./CommonStyle"

const cardList = [
    {
        title: 'Networks',
        imgUrl: card1Img,
        describe: 'Chainge, a premier bridge infrastructure provider, currently supports 55 chains. Our integration capabilities extend to 99% of all existing chains, ensuring comprehensive connectivity.'
    },
    {
        title: 'DEXs',
        imgUrl: card2Img,
        describe: `Explore the depths of cross-chain liquidity and access dozens of DEXs in one place. We've intrgrated a diverse selection of decentralized exchanges to offer you a seamless and effective trading experience.`
    },
    {
        title: 'Aggregators',
        imgUrl: card3Img,
        describe: 'Chainge stands out as the most liquid cross-chain aggregator on the market. Empowered by a top-tier smart router and our tier one aggregator partners.'
    },
    {
        title: 'Web3 browsers',
        imgUrl: card4Img,
        describe: 'Decentralized browsing is becoming a reality. Our Web3 partners provide a user-friendly gateway to explore the vast landscape of blockchain apps and services.'
    },
    {
        title: 'Wallets',
        imgUrl: card5Img,
        describe: 'Discover a variety of partner-wallets within the Chainge ecosystem. Each of them offers unique features, ensuring a wide range of approaches to asset management.'
    }
]

const ScrollCardCom = () => {
    const [progress, setProgress] = useState(0)

    const fnProgressChange = (progress: number) => {
        setProgress(progress)
    }

    return <Parallax
            onProgressChange={fnProgressChange}
        >
            <ScrollCardWrap>
                <AnimationWrap
                    animate={{transform: `translate3d(0px, 0px, 0px) ${progress > 0.2 ? '' : `scale(${0.7664 + (progress * 1.2)}, ${0.7664 + (progress * 1.2)})`}` }}
                    transition={{ duration: 0 }}
                >
                    <ScrollCardContentWrap>
                        <AnimationScrollWrap
                            animate={{transform: `translate3d(${ progress < 0.2 ? '0px' : `calc(((100vw * -1) + (100px * -1)) * ${progress > 0.7 ? 1.51 : ((progress - 0.2) * 3)})` }, 0px, 0px)` }}
                            transition={{ duration: 0 }}
                        >
                            <ScrollTitleBoxWrap
                                initial={{}}
                                animate={{transform: `translateX(calc((50vw - 50%) * (1 - ${progress > 0.7 ? 0.7 : progress})))` }}
                                transition={{ duration: 0 }}
                            >
                                <ScrollTitleWrap>Navigate our<br/>multi-dimensional realm</ScrollTitleWrap>
                            </ScrollTitleBoxWrap>
                            {
                                cardList.map((item, index) => 
                                    <AnimationWrap key={index}
                                        animate={{transform: `translateX(calc(50vw * (1 - ${progress > 0.7 ? 0.7 : progress})))` }}
                                        transition={{ duration: 0 }}
                                    >
                                        <CardWrap>
                                            <CardImgWrap src={item.imgUrl}></CardImgWrap>
                                            <CardTitleWrap>{item.title}</CardTitleWrap>
                                            <CardDescWrap>{item.describe}</CardDescWrap>
                                            {/* <StyledContainer>
                                                <CardBtnWrap>
                                                    Read more
                                                    <ArrowRightSvgWrap></ArrowRightSvgWrap>
                                                </CardBtnWrap>
                                            </StyledContainer> */}
                                        </CardWrap>
                                    </AnimationWrap>
                                )
                            }
                            
                        </AnimationScrollWrap>
                        <Media1024BoxWrap>
                            <ScrollTitleWrap $roobertSemiBold>Navigate our<br/>multi-dimensional realm</ScrollTitleWrap>
                            {
                                cardList.map((item, index) => 
                                    <CardWrap key={index}>
                                        <CardImgWrap src={item.imgUrl}></CardImgWrap>
                                        <CardTitleWrap>{item.title}</CardTitleWrap>
                                        <CardDescWrap>{item.describe}</CardDescWrap>
                                        {/* <StyledContainer>
                                            <CardBtnWrap>
                                                Read more
                                                <ArrowRightSvgWrap></ArrowRightSvgWrap>
                                            </CardBtnWrap>
                                        </StyledContainer> */}
                                    </CardWrap>
                                )
                            }
                        </Media1024BoxWrap>
                    </ScrollCardContentWrap>
                </AnimationWrap>
            </ScrollCardWrap>

            <StickyEmptyWrap></StickyEmptyWrap>
        </Parallax>
}

const ScrollCardWrap = styled(StyledContainer)`
    position: relative;
    padding: 30px 24px;
    background-color: #141414;

    position: sticky;
    top: 98px;

    ${
        mediaMax1024(`
            padding: 30px 8px;

            position: relative;
            top: 0px;
        `)
    }
`

const ScrollCardContentWrap = styled(StyledContainer)`
    width: calc(100vw - 24px - 24px);
    height: calc(100vh - 98px - 30px - 30px);
    background-color: #1F1F1F;
    border-radius: 9px;
    overflow: hidden;

    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;

    ${
        mediaMax1024(`
            padding: 30px var(--safe-area-x-wrapper);
            width: 100%;
            height: auto;
        `)
    }
`

const ScrollTitleWrap = styled(StyledContainer)`
    font-size: max(40px, 30px);
    font-weight: 400;
    font-family: RoobertRegular;
    line-height: 1.2;
    text-align: center;
    padding: var(--safe-area-x-wrapper);
    ${
        mediaMax1024(`
            font-size: 20px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 30px;
        `)
    }
`

const ScrollTitleBoxWrap = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 100%;
    width: calc((100vw - 48px) / 32 * 20);
`

const CardWrap = styled(Column)`
    height: 100%;
    width: 35vw;
    background-color: #0A0A0A;
    border-radius: 9px;
    padding: calc(var(--safe-area-x-content) - var(--safe-area-x-wrapper));
    box-sizing: border-box;

    ${
        mediaMax1024(`
            width: 100%;
            margin-bottom: 10px;
            padding: 30px 30px 43px;

            &:last-child {
                margin: 0;
            }
        `)
    }
`

const CardImgWrap = styled.img`
    width: 15vw;
    height: 15vw;
    overflow: hidden;

    ${
        mediaMax1024(`
            width: 35vw;
            height: 35vw;
        `)
    }
`

const CardTitleWrap = styled(StyledContainer)`
    margin-top: 6%;
    margin-bottom: 3%;
    font-size: max(30px, 10px);
    line-height: 1;
    font-weight: 300;
    font-family: RoobertLight;

    ${
        mediaMax1024(`
            margin-top: 30px;
            margin-bottom: 15px;
        `)
    }
`

const CardDescWrap = styled(StyledContainer)`
    margin-bottom: 3%;
    font-size: max(18px, 16px);
    line-height: 1.1;
    font-weight: 400;
    font-family: RoobertRegular;
    color: #767A7F;

    ${
        mediaMax1024(`
            font-size: 16px;
            margin-bottom: 20px;
        `)
    }
`

const ArrowRightSvgWrap = styled(ArrowRightSvg)`
    margin-left: 6px;
    rect, path {
        transition: all linear 0.2s;
    }
`

const CardBtnWrap = styled(Row)`
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    background-color: #1F1F1F;
    font-size: max(14px, calc(10px + 0.2vw));
    color: #A0A3A7;
    display: inline-flex;
    cursor: pointer;

    &:hover {
        background-color: #F2F2F2;
        color: #0A0A0A;
        ${ArrowRightSvgWrap} {
            rect, path {
                fill: #0A0A0A;
            }
        }
    }
`

const AnimationWrap = styled(motion.div)``
const AnimationScrollWrap = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(6, max-content);
    grid-column-gap: 20px;
    grid-auto-rows: 100%;
    will-change: transform;
    width: max-content;
    height: 100%;

    ${
        mediaMax1024(`
            display: none !important;
        `)
    }
`

const Media1024BoxWrap = styled(Column)`
    display: none;
    ${
        mediaMax1024(`
            display: block !important;
        `)
    }
`

const StickyEmptyWrap = styled(StyledContainer)`
    height: 250vh;
    ${
        mediaMax1024(`
            display: none !important;
        `)
    }
`

export default ScrollCardCom