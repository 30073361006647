import styled from "styled-components"
import StyledContainer from "./styledContainer"

export const defaultTheme = {
    mode: 'day',
}

export const darkTheme = {
    mode: 'dark',
}

export const Row = styled(StyledContainer)`
    display: flex;
    align-items: center;
`

export const RowCenter = styled(StyledContainer)`
   display: flex;
   align-items: center;
   justify-content: center;
`

export const Column = styled(StyledContainer)`
    display: flex;
    flex-direction: column;
`

export const ColumnCenter = styled(StyledContainer)`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
`