import { motion } from "framer-motion"
import { ReactNode } from "react"
import styled from "styled-components"

type Props = {
    children: JSX.Element
    delay?: number
    duration?: number
    initial?: {[key:string]: any}
    animate?: {[key:string]: any}
}
const AnimateCom = ({children, delay=0, duration=1.25, initial={opacity: 0, transform: 'translateY(10px)'}, animate={opacity: 1, transform: 'translateY(0px)'}}: Props) => {
    return <AnimateBoxWrap
        initial={initial}
        animate={animate}
        transition={{
            duration: duration,
            delay: delay
        }}
    >
        {children}
    </AnimateBoxWrap>
}

const AnimateBoxWrap = styled(motion.div)``


export default AnimateCom