import { Outlet } from "react-router-dom"
import StyledContainer from "style/styledContainer"
import styled from "styled-components"
import HeaderCom from "./components/HeaderCom"
import FooterCom from "./components/FooterCom"

const MainLayout = () => {
    return <MainLayoutWrap>
        <HeaderCom></HeaderCom>
        <BodyWrap>
            <Outlet></Outlet>
        </BodyWrap>
        <FooterCom></FooterCom>
    </MainLayoutWrap> 
}

const MainLayoutWrap = styled(StyledContainer)`
`

const BodyWrap = styled(StyledContainer)`
`

export default MainLayout