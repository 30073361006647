import { RowCenter } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled, { css } from "styled-components"

import playImg from 'assets/images/play_icon.png'
import dotsImg from 'assets/images/dots.png'
import { useEffect, useState } from "react"

import { ReactComponent as VideoCloseSvg } from 'assets/svgs/video_close.svg'

import { mediaMax1024 } from "./CommonStyle"

const VideoCom = () => {
    const [showAnimate, setShowAnimate] = useState(false)
    const [showIndex, setShowIndex] = useState(1)
    const [showVideo, setShowVideo] = useState(false)

    useEffect(() => {
        const timer = setInterval(() => {
            if(showIndex === 4) {
                setShowIndex(1)
            } else {
                setShowIndex(showIndex+1)
            }
        }, 3000)

        return () => {
            clearInterval(timer)
        }
    }, [showIndex])
    

    return <VideoBoxWrap>
            <VideoWrap>
                <DescBoxWrap>
                    <LeftWrap>One Marketplace. All Crypto.</LeftWrap>
                    <RightWrap>
                        <StyledContainer>Embark on an expedition through the Cryptoverse with Chainge as your compass</StyledContainer>
                        <StyledContainer $mt="10px" $fontWeight4 $roobertRegular $color="#767A7F">Our products empower you to securely store, discover, exchange, transfer and bridge assets effortlessly. Dive in and explore the possibilities—you name it, we've got you covered</StyledContainer>
                    </RightWrap>
                </DescBoxWrap>

                <VideoPlayBoxWrap>
                    <StyledContainer $height="300px"></StyledContainer>
                    <DotsWrap src={dotsImg}></DotsWrap>
                    <VideoPlayWrap onMouseOver={() => setShowAnimate(true)} onMouseLeave={() => setShowAnimate(false)}>
                        {
                            showAnimate && <WaveBoxWrap $delay={0.15} $showAnimate={showAnimate}></WaveBoxWrap>
                        }
                        <PlayBtnImgWrap src={playImg} onClick={() => setShowVideo(true)}></PlayBtnImgWrap>
                    </VideoPlayWrap>


                    <SpanWrap>
                        {
                            showIndex === 1 &&  <LeftTextWrap>
                                <span>Chainge</span> <span>transcends</span> <span>Defi</span> 
                            </LeftTextWrap>
                        }
                         {
                            showIndex === 2 &&  <RightTextWrap>
                                <span>Guiding</span> <span>your</span> <span>Cryptoverse</span> <span>voyage</span>
                            </RightTextWrap>
                        }
                        {
                            showIndex === 3 &&  <LeftTextWrap>
                                <span>Discover.</span> <span>Transform.</span> <span>Evolve</span>
                            </LeftTextWrap>
                        }
                        {
                            showIndex === 4 &&  <RightTextWrap>
                                <span>Rediscover</span> <span>limitless</span> <span>possibilties</span>
                            </RightTextWrap>
                        }
                        {/* <LeftTextWrap><span>Chainge</span> <span>transcends</span> <span>Defi</span> <span>Guiding</span> <span>your</span> <span>Cryptoverse</span> <span>voyage</span></LeftTextWrap> */}
                        {/* <LeftTextWrap><span>Discover.</span> <span>Transform.</span> <span>Evolve</span> <span>Rediscover</span> <span>limitless</span> <span>possibilties</span></LeftTextWrap> */}
                        {/* <RightTextWrap><span>Guiding</span> <span>your</span> <span>Cryptoverse</span> <span>voyage</span></RightTextWrap> */}
                        {/* <RightTextWrap><span>Rediscover</span> <span>limitless</span> <span>possibilties</span></RightTextWrap> */}
                    </SpanWrap>
                </VideoPlayBoxWrap>
            </VideoWrap>

            {
                showVideo && <VidoeWinWrap>
                    <VidoeMaskWrap></VidoeMaskWrap>
                    <VideoWinContentWrap>
                        <VideoCloseSvgWrap onClick={() => setShowVideo(false)}></VideoCloseSvgWrap>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/D4X3b5qZKBk?si=bkK04PnLiqTQtj9q?autoplay=1" frameBorder="0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </VideoWinContentWrap>
                </VidoeWinWrap>
            }
            
        </VideoBoxWrap>
}

const VideoBoxWrap = styled(StyledContainer)``

const VideoWrap = styled(StyledContainer)`
    // height: 100vh;
    background-color: #000000;

    padding: 110px var(--safe-area-x) 167px;
    box-sizing: border-box;

    ${
        mediaMax1024(`
            padding: 70px var(--safe-area-x) 167px;
        `)
    }
`

const DescBoxWrap = styled(StyledContainer)`
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    
`

const LeftWrap = styled(StyledContainer)`
    font-size: max(20px, 19px);
    font-weight: 600;
    line-height: 1;
    color: #ffffff;
    font-family: RoobertSemiBold;

    max-width: 60%;
    flex: 0 0 60%;

    @media screen and (max-width: 1440px) and (min-width: 1024px){
        max-width: 52%;
        flex: 0 0 52%; 
    }
    ${
        mediaMax1024(`
            flex: 0 0 100%;
            max-width: 100%;
        `)
    }

`

const RightWrap = styled(StyledContainer)`
    text-align: right;
    flex: 0 0 40%;
    max-width: 40%;
    &>div {
        font-size: max(22px, 20px);
        line-height: 1.2;
    }

    @media screen and (max-width: 1440px) and (min-width: 1024px){
        max-width: 48%;
        flex: 0 0 48%; 
    }
    ${
        mediaMax1024(`
            margin-top: 20px;
            text-align: left;
            flex: 0 0 100%;
            max-width: 100%;
        `)
    }
`


const VideoPlayBoxWrap = styled(StyledContainer)`
    width: 100%;
    position: relative;
    margin-top: 70px;
`


const PlayBtnImgWrap = styled.img`
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: all linear 0.2s;
    position: relative;
    z-index: 2;
`


const WaveBoxWrap = styled.div<{$delay: number, $showAnimate: boolean}>`
    @keyframes waveAnime {
        0% {
            width: 190px;
            height: 190px;
        }

        50% {
            width: 240px;
            height: 240px;
        }

        100% {
            width: 190px;
            height: 190px;
        }
    }

    box-shadow: inset 0px 0px 29px 0px #6337FF;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${
        ({$showAnimate, $delay}) => $showAnimate ? css`
            animation: waveAnime 5s infinite ${$delay}s;
        `: css``
    }
`

const DotsWrap = styled.img`
    @keyframes waveAnime2 {
        0% {
            width: 250px;
            height: 250px;
        }

        50% {
            width: 300px;
            height: 300px;
        }

        100% {
            width: 250px;
            height: 250px;
        }
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
`

const VideoPlayWrap = styled(RowCenter)`
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #000000;

    width: 212px;
    height: 212px;

    transform: translate(-50%, -50%);

    border: 2px solid #6337FF;
    border-radius: 50%;

    box-shadow: inset 0px 0px 29px 0px #6337FF;
    
    &:hover {
        width: 190px;
        height: 190px;
        box-shadow: 0px 0px;

        ${PlayBtnImgWrap} {
            width: 56px;
            height: 56px;
        }
    }
`

const LeftTextWrap = styled(StyledContainer)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    font-size: max(16px, 15px);
    font-weight: 400;
    line-height: 1;
    font-family: RoobertRegular;
    color: #987BFF;
    margin-left: -370px;
    white-space: nowrap;

    ${
        mediaMax1024(`
            top: calc(50% + 150px + 66px);
            left: 50%;
            transform: translate(-50%, -50%);
            margin-left: 0 !important;
        `)
    }
`

const RightTextWrap = styled(LeftTextWrap)<{$opacity: number}>`
    opacity: ${({$opacity}) => $opacity};
    margin-left: 180px;
`

const SpanWrap = styled.div`
  span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
  }
  
  span:nth-child(1) {
    animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(2) {
    animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(3) {
    animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(4) {
    // margin-left: 360px;
    animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.0s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(5) {
    animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(6) {
    animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(7) {
    animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  
  span:nth-child(8) {
    animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.0s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(9) {
    animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(10) {
    animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(11) {
    animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(12) {
    animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  span:nth-child(13) {
    animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0), fade-out 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
  }
  
  @keyframes fade-in {
    100% {
      opacity: 1;
      filter: blur(0);
    }
  }

  @keyframes fade-out {
    100% {
      opacity: 0;
      filter: blur(4);
    }
  }
`


// vidoe win box
const VidoeWinWrap = styled(StyledContainer)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`

const VidoeMaskWrap = styled(StyledContainer)`
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;
`

const VideoWinContentWrap = styled(StyledContainer)`
  width: 60vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 16 / 9;

  &>video {
    width: 100%;
  }
`

const VideoCloseSvgWrap = styled(VideoCloseSvg)`
    position: absolute;
    top: -55px;
    right: -55px;
    cursor: pointer;
`

export default VideoCom