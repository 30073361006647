import IconCom from "components/IconCom"
import StyledContainer from "style/styledContainer"
import styled from "styled-components"

import { ReactComponent as WebIconSvg } from 'assets/svgs/web_icon.svg'
import { ReactComponent as AppStoreIconSvg } from 'assets/svgs/appstore_icon.svg'
import { ReactComponent as GoogleIconSvg } from 'assets/svgs/googleplay_icon.svg'
import { ReactComponent as MediumIconSvg } from 'assets/svgs/medum_icon.svg'
import { useCallback } from "react"
import useUnits from "hooks/useUnits"
import { useAppDispatch } from "hooks/redux/hooks"
import { setOpenHubMenu } from "hooks/redux/slices/appSlice"
import { AnimatePresence, motion } from "framer-motion"

interface Props {
    open: boolean
    onClose: () => void
}
const HubWinCom = ({open, onClose}: Props) => {
    const { openBrowserByDownload, openBrowserByUrl } = useUnits()

    return <HubWinOutBoxWrap>
        <HubWinMaskWrap onClick={onClose}></HubWinMaskWrap>
        <AnimatePresence>
            {
                open && <AnimateHubWinWrap
                    initial={{right: '-330px'}}
                    animate={{right: '20px'}}
                    exit={{right: '-330px'}}
                    transition={{
                        duration: 0.2
                    }}
                >
                    <StyledContainer $pl="15px" $fontSize="22px" $lineHeight="26px" $fontWeight3 $roobertLight>Chainge Hub<br/>in a glance</StyledContainer>
                    <StyledContainer $height="40px"></StyledContainer>
                    <ScrollBoxWrap>
                        <StyledContainer $pl="15px" $mb="12px" $fontSize="14px" $lineHeight="16px" $color="#767A7F" $fontWeight4 $roobertRegular>Products</StyledContainer>
                        <CardWrap onClick={() => openBrowserByDownload('dapp')}>
                            <IconCom Svg={WebIconSvg} size="20"></IconCom>
                            <StyledContainer $height="12px"></StyledContainer>
                            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $mb="8px">Web dApp</StyledContainer>
                            <StyledContainer $color="#767A7F" $mb="12px" $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>Your all-in-one desktop Dex for swift, secure, & limitless cross-chain transactions</StyledContainer>
                            <StyledContainer $color="#525252" $fontSize="10px" $lineHeight="12px" $fontWeight4 $roobertRegular>Version 2.0 is live</StyledContainer>
                        </CardWrap>
                        <CardWrap onClick={() => openBrowserByDownload('ios')}>
                            <IconCom Svg={AppStoreIconSvg} size="20"></IconCom>
                            <StyledContainer $height="12px"></StyledContainer>
                            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $mb="8px">iOS Application</StyledContainer>
                            <StyledContainer $color="#767A7F" $mb="12px" $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>Your pocket-sized crypto hub: trade, store, and bridge on the go - On Appstore</StyledContainer>
                            <StyledContainer $color="#525252" $fontSize="10px" $lineHeight="12px" $fontWeight4 $roobertRegular>Version 2.0 coming soon</StyledContainer>
                        </CardWrap>
                        <CardWrap onClick={() => openBrowserByDownload('google')}>
                            <IconCom Svg={GoogleIconSvg} size="20"></IconCom>
                            <StyledContainer $height="12px"></StyledContainer>
                            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $mb="8px">Android Application</StyledContainer>
                            <StyledContainer $color="#767A7F" $mb="12px" $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>Your pocket-sized crypto hub: trade, store, and bridge on the go - On Google Play</StyledContainer>
                            <StyledContainer $color="#525252" $fontSize="10px" $lineHeight="12px" $fontWeight4 $roobertRegular>Version 2.0 coming soon</StyledContainer>
                        </CardWrap>

                        <StyledContainer $height="20px"></StyledContainer>
                        <StyledContainer $pl="15px" $mb="12px" $fontSize="14px" $lineHeight="16px" $color="#767A7F" $fontWeight4 $roobertRegular>Ecosystem news</StyledContainer>
                        <CardWrap onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/chainge-a-transformative-leap-forward-in-decentralized-finance-e17df613f418')}>
                            <IconCom Svg={MediumIconSvg} size="20"></IconCom>
                            <StyledContainer $height="12px"></StyledContainer>
                            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $mb="8px">Acquisition</StyledContainer>
                            <StyledContainer $color="#767A7F" $mb="12px" $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>A transformative leap forward in decentralized finance</StyledContainer>
                        </CardWrap>
                        <CardWrap onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/unveiling-xchng-a-blueprint-for-sustainability-and-growth-c0efb557b31f')}>
                            <IconCom Svg={MediumIconSvg} size="20"></IconCom>
                            <StyledContainer $height="12px"></StyledContainer>
                            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $mb="8px">XCHNG Tokenomics</StyledContainer>
                            <StyledContainer $color="#767A7F" $mb="12px" $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>XCHNG Tokenomics. A Blueprint for Sustainability and Growth</StyledContainer>
                        </CardWrap>
                        <CardWrap onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/embracing-2024-changes-discover-new-ways-to-earn-60471bdfaaf8')}>
                            <IconCom Svg={MediumIconSvg} size="20"></IconCom>
                            <StyledContainer $height="12px"></StyledContainer>
                            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $mb="8px">New Ways To Earn</StyledContainer>
                            <StyledContainer $color="#767A7F" $mb="12px" $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>Embracing 2024 Changes: Discover New Ways To Earn</StyledContainer>
                        </CardWrap>
                    </ScrollBoxWrap>
                </AnimateHubWinWrap>
            }
        </AnimatePresence>

    </HubWinOutBoxWrap>
}

const HubWinOutBoxWrap = styled(StyledContainer)`
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 100
`

const HubWinMaskWrap = styled(StyledContainer)`
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 101
`

const HubWinWrap = styled(StyledContainer)`
    position: absolute;
    width: 310px;
    height: calc(100vh - 108px - 30px);
    top: 108px;
    right: 20px;
    border-radius: 9px;
    background-color: #000000;
    z-index: 102;
    box-sizing: border-box;

    padding: 30px 15px;
`

const AnimateHubWinWrap = styled(motion(HubWinWrap))``

const ScrollBoxWrap = styled(StyledContainer)`
    height: calc(100% - 80px);
    overflow: auto;
`

const CardWrap = styled(StyledContainer)`
    padding: 20px;
    background-color: #141414;
    border-radius: 6px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
        background-color: #292929 !important;
        &>div {
            &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                color: #fff !important;
            }
        }
    }
`

export default HubWinCom