import { Column, Row } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled from "styled-components"

import status1 from 'assets/images/status_1.png'
import status2 from 'assets/images/status_2.png'
import status3 from 'assets/images/status_3.png'
import status4 from 'assets/images/status_4.png'
import { useCallback, useEffect, useState } from "react"
import { mediaMax1024 } from "./CommonStyle"

const stateList = ['Trading volume', 'Aggregated liquidity', 'Number of trades', 'Supported chains']

const StatsCom = () => {
    const [curTab, setCurTab] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            if(curTab === 3) {
                setCurTab(0)
            } else {
                setCurTab(curTab+1)
            }
        }, 5000);
    }, [curTab])

    const getIcon = useCallback(() => {
        switch(curTab) {
            case 0:
                return status1
            case 1:
                return status2
            case 2: 
                return status3
            case 3:
                return status4
        }
    }, [curTab])

    const getIconImage = useCallback(() => {
        return <ImgWrap src={getIcon()}></ImgWrap>
    }, [getIcon]) 

    const getStatusCard = useCallback(() => {
        let numStr = ''
        let desc = ''
        let color = ''
        switch(curTab) {
            case 0:
                numStr = '$1Bn+'
                desc = 'Cumulative trading volume'
                color = '#F6FB79'
                break;
            case 1:
                numStr = '$40Bn+'
                desc = 'Total aggregated liquidity'
                color = '#99FF9E'
                break;
            case 2:
                numStr = '1M+'
                desc = 'Total number of trades'
                color = '#FD94FF'
                break;
            case 3:
                numStr = '55'
                desc = 'No. of supported chains'
                color = '#987BFF'
                break;
        }

        return <StatsBarWrap $backgroundColor={color}>
            <NumBerWrap $color={color}>{numStr}</NumBerWrap>
            <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $color="#0A0A0A">{desc}</StyledContainer>
        </StatsBarWrap>
    }, [curTab])

    const getStatusText = useCallback(() => {
        let desc = '';
        switch(curTab) {
            case 0:
                desc = 'Trading volume'
                break;
            case 1:
                desc = 'Aggregated liquidity'
                break;
            case 2:
                desc = 'Number of trades'
                break;
            case 3:
                desc = 'Supported chains'
                break;
        }
        return desc
    }, [curTab])

    return <StatsWrap>
        <Box1024Wrap>
            {
                getStatusCard()
            }
            <StyledContainer $height="20px"></StyledContainer>
            <StyledContainer>
                {
                    getIconImage()
                }
            </StyledContainer>
        </Box1024Wrap>
        <StatsConentWrap $justifyContent="space-between">
            {
                getIconImage()
            }
            {
                getStatusCard()
            }
        </StatsConentWrap>
        <TextBoxWrap>
            {
                stateList.map((item, index) => <StateTextWrap key={index} $isShow={index === curTab}>{item}</StateTextWrap>)
            }
        </TextBoxWrap>
        <TextBox1024Wrap $justifyContent="space-between">
            <span>{getStatusText()}</span>
            <Row $display="inline-flex !important">
                {
                    stateList.map((item, index) => <ProgressTibWrap key={index} $isShow={index === curTab}></ProgressTibWrap>)
                }
            </Row>
        </TextBox1024Wrap>
    </StatsWrap>
}

const StatsWrap = styled(StyledContainer)`
    padding: var(--safe-area-x);
    padding-top: 38px;
    padding-bottom: 12px;
`

const StatsConentWrap = styled(Row)`
    // margin: 0 118px;
    padding-bottom: 10px;

    ${
        mediaMax1024(`
            display: none !important;

        `)
    }
`

const Box1024Wrap = styled(Column)`
    display: none;
    ${
        mediaMax1024(`
            display: flex !important;

            // margin: 0 24px;
            padding-bottom: 10px;
        `)
    }
`

const NumBerWrap = styled(StyledContainer)`
    padding: 12px;
    font-size: 18px;
    line-height: 1;
    background-color: #141414;
    border-radius: 9px;
    margin-right: 16px;

`

const StatsBarWrap = styled(Row)`
    padding: 11px 10px;
    border-radius: 9px;
    box-sizing: border-box;
    width: 354px;

    ${
        mediaMax1024(`
            width: 100%;
        `)
    }

    display: inline-flex;

`

const ImgWrap = styled.img`
    height: 105px;
    ${
        mediaMax1024(`
            height: 85px;
        `)
    }
`

const StateTextWrap = styled(StyledContainer)<{$isShow: boolean}>`
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    font-family: RoobertRegular;
    margin-right: 30px;
    color: ${({$isShow}) => $isShow ? '#ffffff': '#525252'} ;

    position: relative;

    @keyframes sheen {
        from {
            width: 0px;
        }

        to {
            width: 22px;
        }
    }

    &::after {
        content: '';
        display: ${({$isShow}) => $isShow ? 'block': 'none'};
        width: 0;
        height: 2px;
        border-radius: 2px;
        background-color: #ffffff;

        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translate(-50%, 0);

        animation: sheen 5s infinite;
    }
`


const TextBoxWrap = styled(Row)`
    // margin: 0 118px;
    ${
        mediaMax1024(`
            display: none;
        `)
    }
`

const TextBox1024Wrap = styled(Row)`
    margin: 0 24px;
    &>span {
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
    }

    display: none;

    ${
        mediaMax1024(`
            display: flex !important;
        `)
    }
`

const ProgressTibWrap = styled(Row)<{$isShow: boolean}>`
    width: 4px;
    height: 2px;
    border-radius: 2px;
    margin-left: 6px;
    background-color: ${({$isShow}) => $isShow ? '#ffffff': '#525252'};
    
    @keyframes sheen2 {
        from {
            width: 4px;
            color: #ffffff;
        }

        to {
            width: 22px;
            color: #ffffff;
        }
    }

    animation: ${({$isShow}) => $isShow ? 'sheen2 5s infinite': 'none'}; 
`

export default StatsCom