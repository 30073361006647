import StyledContainer from "style/styledContainer"
import styled, { css } from "styled-components"

import dappImg from 'assets/images/dapp_tag.png'
import dappHoverImg from 'assets/images/dapp_tag_hover.png'

import iosImg from 'assets/images/ios_tag.png'
import iosHoverImg from 'assets/images/ios_tag_hover.png'

import googleImg from 'assets/images/google_tag.png'
import googleHoverImg from 'assets/images/google_tag_hover.png'

import qrCodeImg from 'assets/images/qr_code.png'


import { Row } from "style/preset"
import { useState } from "react"
import { mediaMax1024 } from "./CommonStyle"
import useUnits from "hooks/useUnits"

const DownloadCom = () => {
    const { openBrowserByDownload } = useUnits()
    const [dappImgUrl, setDappImgUrl] = useState(dappImg)
    const [iosImgUrl, setIosImgUrl] = useState(iosImg)
    const [googleImgUrl, setGoogleImgUrl] = useState(googleImg)

    return <DownloadBoxWrap>
        <DownloadWrap>
            <LeftBoxWrap>
                <StyledContainer $fontSize="40px" $lineHeight="48px" $fontWeight5 $color="#0A0A0A">Where innovation<br/> merges with imagination.</StyledContainer>
                <StyledContainer $height="40px"></StyledContainer>
                <Row>
                    <TagImgWrap src={dappImgUrl} $width="124px" $height="35px" onClick={() => openBrowserByDownload('dapp')} onMouseOver={() => setDappImgUrl(dappHoverImg)} onMouseLeave={() => setDappImgUrl(dappImg)}></TagImgWrap>
                    <TagImgWrap src={iosImgUrl} $width="125px" $height="35px" onClick={() => openBrowserByDownload('ios')}  onMouseOver={() => setIosImgUrl(iosHoverImg)} onMouseLeave={() => setIosImgUrl(iosImg)}></TagImgWrap>
                    <TagImgWrap src={googleImgUrl} $width="118px" $height="35px" onClick={() => openBrowserByDownload('google')}  onMouseOver={() => setGoogleImgUrl(googleHoverImg)} onMouseLeave={() => setGoogleImgUrl(googleImg)}></TagImgWrap>
                </Row>
            </LeftBoxWrap>
            <StyledContainer $flex1="1"></StyledContainer>
            <RightBoxWrap>
                <QrCodeImgWrap src={qrCodeImg}></QrCodeImgWrap>
                <StyledContainer $height="20px" $width="30px"></StyledContainer>
                <StyledContainer $fontSize="22px" $lineHeight="26px" $fontWeight5 $color="#0A0A0A">In the infinite Cryptoverse<br/>every trajectory births a revelation</StyledContainer>
            </RightBoxWrap>
        </DownloadWrap>
    </DownloadBoxWrap>
}

const DownloadBoxWrap = styled(StyledContainer)`
    position: relative;
    padding: 30px var(--safe-area-x-wrapper);
    background-color: #141414;

    ${
        mediaMax1024(`
            // padding: 30px 8px;
        `)
    }
`

const DownloadWrap = styled(StyledContainer)`
    padding: 109px var(--safe-area-x-content);
    background-color: #F6FB79;
    border-radius: 9px;

    display: flex;
    align-items: center;

    ${
        mediaMax1024(`
            padding: 30px var(--safe-area-x-content);
            flex-direction: column;
            align-items: flex-start;
        `)
    }
`

const LeftBoxWrap = styled(StyledContainer)`

`

const TagImgWrap = styled.img<{$width: string, $height: string}>`
    cursor: pointer;
    margin-right: 6px;

    &:last-child {
        margin: 0;
    }
    ${
        ({$width, $height}) => css`
            width: ${$width};
            height: ${$height};
        `
    }

    ${
        mediaMax1024(`
            width: calc((100% - 12px) / 3);
            height: calc(((100% - 12px) / 3) * 35 / 125);
        `)
    }
`


const RightBoxWrap = styled(StyledContainer)`
    text-align: right;

    ${
        mediaMax1024(`
            margin-top: 50px;
            text-align: left;

            display: flex;
            align-items: center;
        `)
    }
`

const QrCodeImgWrap = styled.img`
    with: 100px;
    height: 100px;
`

export default DownloadCom