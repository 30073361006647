interface IconProps {
    width?: string
    height?: string
    size?: string
    Svg: any
    otherProps?: any
}
const IconCom = ({ width, height, size="16", Svg, ...otherProps}: IconProps) => (
    <Svg width={ width || size} height={ height || size} {...otherProps}/>
);

export default IconCom