import StyledContainer from "style/styledContainer"
import styled from "styled-components"

import icebergImg from 'assets/images/iceberg-illustration.png'
import solutionImg from 'assets/images/solution.png'
import { mediaMax1024 } from "./CommonStyle"

const SolutionsBarCom = () => {
    return <SolutionsBarWrap>
        <SolutionImgWrap src={solutionImg}></SolutionImgWrap>
        <IcebergImgWrap src={icebergImg}></IcebergImgWrap>
    </SolutionsBarWrap>
}

const SolutionsBarWrap = styled(StyledContainer)`
    // border: red solid 2px;
    // position
    position: relative;
    height: 190px;

    // layout
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: var(--safe-area-x);
    padding-right: var(--safe-area-x);
    
    // style
    background-color: #141414;
    overflow: hidden;

    ${
        mediaMax1024(`
            height: 130px;
            // padding-left: 24px;
            // padding-right: 24px;
        `)
    }
`

const SolutionImgWrap = styled.img`
    height: 50%;
    ${
        mediaMax1024(`
            height: 40%;
            padding-bottom: 10px;
        `)
    }
`

const IcebergImgWrap = styled.img`
    height: 80%;
`

export default SolutionsBarCom