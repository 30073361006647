import IconCom from "components/IconCom"
import { Column, Row, RowCenter } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled, { css } from "styled-components"
import { Tooltip } from "react-tooltip"

import { ReactComponent as SolIcon1Svg } from 'assets/svgs/solution_icon_1.svg'
import { ReactComponent as SolIcon2Svg } from 'assets/svgs/solution_icon_2.svg'
import { ReactComponent as SolIcon3Svg } from 'assets/svgs/solution_icon_3.svg'
import { ReactComponent as SolIcon4Svg } from 'assets/svgs/solution_icon_4.svg'
import { ReactComponent as SolIcon5Svg } from 'assets/svgs/solution_icon_5.svg'
import { ReactComponent as SolIcon6Svg } from 'assets/svgs/solution_icon_6.svg'
import { ReactComponent as SolIcon7Svg } from 'assets/svgs/solution_icon_7.svg'


import leftDotsImg from 'assets/images/left_dots.png'
import { useEffect, useState } from "react"
import { mediaMax1024 } from "./CommonStyle"
import { useAppSelector } from "hooks/redux/hooks"


const menu = [
    {
        svg: SolIcon1Svg,
        label: 'Trading',
        title: 'Elevated trading',
        tags: ['Cross-Chain Swaps', 'Express Bridging', 'Limit Orders', 'Trade & Send', 'Perpetuals'],
        des1: 'Empower your trading experience and benefit from the best prices on the market',
        des2: 'Seamlessly access thousands of assets across 55+ chains while enjoying unparalleled liquidity & speed',
        tagDes: [
            'Seamlessly trade thousands of assets across different blockchains, enabling you to diversify your portfolio effortlessly and benefit from maximum cross-chain aggregated liquidity from hundreds of DEXs.',
            'Fast, easy and safe. Instantly move assets between chains, streamlining the process of navigating diverse blockchain ecosystems with ease and efficiency.',
            'Take control of your trading strategy by setting specific conditions for buying or selling assets at predefined prices, ensuring precise execution in the market.',
            'Execute trades and automatically send the output assets to a wallet of your choice, directly within the Chainge web dApp.',
            'Trade without expiry. A continuous derivative allowing ongoing market participation and flexibility in trading strategies.'
        ]

    },
    {
        svg: SolIcon2Svg,
        label: 'Wallet',
        title: 'More than a wallet',
        tags: ['Account Abstraction', 'Hardware Cold Wallet', 'Automated Operations', 'Token Management'],
        des1: `Prioritize security & operational autonomy with Chainge's cross-chain wallet`,
        des2: `Experience the freedom to execute a wide array of automated transactions while maintaining full custody of your assets`,
        tagDes: [
            'Your assets are held exclusively by smart contracts, reducing reliance on externally-owned accounts (EOAs). This advanced feature not only fortifies security but also enhances user experience by executing programmable smart contracts tailored for user-specific functions.',
            'Ensure grade A security with a physical cold wallet powered by Arculus cutting-edge technology, providing an extra layer of protection for your valuable assets.',
            'Experience the freedom of executing various automated transactions and operations, enhancing the efficiency and convenience of managing your assets.',
            'Effortlessly manage a diverse range of tokens within a comprehensive wallet, giving you full control over your digital assets in one decentralized location.'
        ]
    },
    {
        svg: SolIcon3Svg,
        label: 'Coin blinks',
        title: 'Trend spotting',
        tags: ['Market Analytics', 'Gem Finding', 'Instant Buys/Sells'], //, 'Gem Finding', 'Gem Finding'
        des1: `Find hidden gems by leveraging cutting-edge analytics tools`,
        des2: `Discover rising tokens and stay ahead by exploring emerging opportunities within the vast cryptoverse`,
        tagDes: [
            'In-depth market analysis, empowers you with valuable insights to make informed decisions in the dynamic world of cryptocurrencies.',
            'Discover hidden and promising tokens within the vast cryptoverse, utilizing advanced analytics to identify emerging opportunities and potential market trends.',
            'Quickly execute one-tap buying or selling operations for timely transactions, providing you with the flexibility to act swiftly in response to market changes.',
        ]
    },
    {
        svg: SolIcon4Svg,
        label: 'Natural language',
        title: 'Conversational interface',
        tags: ['Intuitive Interaction', 'Easy Chats', 'User-Centric Design'],
        des1: `Discover the ease of managing your assets with a user-friendly, natural language interface`,
        des2: `Simplifying operations, this innovative feature ensures an intuitive experience, making the world of crypto effortlessly navigable`,
        tagDes: [
            'Immerse yourself in a user-friendly, natural language interface that simplifies crypto operations, making management and transactions both intuitive and accessible.',
            'Engage with Chainge effortlessly through a conversational interface, simplifying the complexities of crypto management for a seamless user experience.',
            'Experience a crypto platform designed with you in mind, where natural language simplifies operations and enhances accessibility for users of all levels.',
        ]
    },
    {
        svg: SolIcon5Svg,
        label: 'Hardware wallet',
        title: 'The Hardware Cold Wallet',
        tags: ['Vault-like Security', 'Next-Gen Tech', 'No cords & buttons', 'Lightning Speed'],
        des1: `Ensure top-notch security with Chainge's hardware cold wallet`,
        des2: `Powered by Arculus, it provides an extra layer of protection for your assets`,
        tagDes: [
            `Ensure top security with Chainge's hardware cold wallet, powered by Arculus and CompoSecure. The hw adds an extra layer of protection to safeguard your valuable assets.`,
            'Leverage the industry-leading CC EAL6+ Secure Element to encrypt and securely store your keys.',
            `Rely on the trusted security features of Arculus to safeguard your assets within Chainge's hardware cold wallet, delivering peace of mind for your crypto holdings.`,
            `Facilitate seamless onboarding and 'tap to transact' features for an instantly responsive user experience`,
        ]
    },
    {
        svg: SolIcon6Svg,
        label: 'Credit card',
        title: 'Shopping without borders',
        tags: ['Global Coverage', 'Secure Transactions', 'Quick & easy KYC'],
        des1: `Use the Chainge Visa Virtual Credit Card for convenient global online shopping`,
        des2: `Providing a convenient off-ramp solution that ensures seamless and secure fiat payments`,
        tagDes: [
            `This convenient virtual off-ramp solution ensures secure USDC to fiat payments, enhancing your crypto shopping experience.`,
            `Shop globally with confidence using the Chainge Visa Virtual Credit Card, a secure and efficient solution for online purchases.`,
            `Experience a swift and user-friendly KYC process, ensuring a quick and seamless identity verification for hassle-free onboarding`,
        ]
    },
    {
        svg: SolIcon7Svg,
        label: 'Earn with Chainge',
        title: 'Passive Income generator',
        tags: ['Daily Rewards', 'No Lock-Up'],
        des1: `Boost your portfolio effortlessly and earn in a decentralized way`,
        des2: `Simply hold $BTC, $ETH, $USDT, $USDC, and $DAI on the Fusion chain or add liquidity to the CHNG/USDT pool for daily APY returns`,
        tagDes: [
            'Collect your daily rewards effortlessly with a simple tap, eliminating the need for prolonged waiting periods',
            'Enjoy unrestricted access to your assets—never face any lock-up periods. Import or transfer them freely whenever you choose.'
        ]
    },
]

const SolutionsCom = () => {
    const { curSolution  } = useAppSelector((state) => state.app)
    const [chosedItem, setChosedItem] = useState(menu[0])
    
    useEffect(() => {
        const index = menu.findIndex(item => item.label.toLowerCase() === curSolution.toLowerCase())
        if(index > -1) {
            setChosedItem(menu[index])
        }
    }, [curSolution])

    return <SolutionWrap>
        <SolutionContentWrap>
            <MenuBoxWrap>
                <StyledContainer $fontSize="20px" $lineHeight="20px" $fontWeight6 $roobertSemiBold>Climb the tip of the iceberg.</StyledContainer>
                <StyledContainer $fontSize="20px" $lineHeight="20px" $mt="8px" $color="#F6FB79">And beyond.</StyledContainer>
                <NormalBoxWrap>
                    <StyledContainer $height="114px"></StyledContainer>
                    {
                        menu.map((item, index) => <MenuItemWrap key={index} onClick={() => setChosedItem(item)} $active={chosedItem.label === item.label}>
                                <SvgIconWrap Svg={item.svg}></SvgIconWrap>
                                <MenuTextWrap>{item.label}</MenuTextWrap>
                            </MenuItemWrap>
                        )
                    }
                </NormalBoxWrap>
                <Media1024BoxWrap>
                    <StyledContainer $height="40px"></StyledContainer>

                    <BtnListWrap>
                        {
                            menu.map((item, index) => <MenuBtnWrap key={index} onClick={() => setChosedItem(item)} $active={chosedItem.label === item.label}>
                                    <SvgIconWrap Svg={item.svg}></SvgIconWrap>
                                    <MenuTextWrap>{item.label}</MenuTextWrap>
                                </MenuBtnWrap>
                            )
                        }
                    </BtnListWrap>
                </Media1024BoxWrap>
            </MenuBoxWrap>

            <DetailBoxWrap>
                <DetailTitleWrap $roobertLight>{chosedItem.title}</DetailTitleWrap>
                <StyledContainer $height="20px"></StyledContainer>
                <DetailTagBoxWrap>
                    {
                        chosedItem.tags.map((item, index) => <StyledContainer key={index}>
                        <DetailTagWrap data-tooltip-id={`${item}_tag_tooltip`}>{item}</DetailTagWrap>
                        <Tooltip id={`${item}_tag_tooltip`} place="top" style={{ backgroundColor:  'transparent', width: '250px', padding: '2px'}}>
                            <TipWrap>
                                {chosedItem?.tagDes[index]}
                            </TipWrap>
                        </Tooltip>
                    </StyledContainer>)
                    }
                </DetailTagBoxWrap>
                <StyledContainer $height="50px"></StyledContainer>
                <DetailSubTitleWrap>Keen on diving deeper?</DetailSubTitleWrap>
                <StyledContainer $height="6px"></StyledContainer>
                <DetailSoonTitleWrap>A dedicated landing page is coming soon</DetailSoonTitleWrap>


                <DetailRightBox>
                    <StyledContainer $width="210px" $height="170px"></StyledContainer>
                    <StyledContainer $height="50px"></StyledContainer>

                    <DetailDescWrap>{chosedItem.des1}</DetailDescWrap>
                    <DetailDescWrap $mt="10px" $color="#767A7F">{chosedItem.des2}</DetailDescWrap>
                </DetailRightBox>
            </DetailBoxWrap>
        </SolutionContentWrap>
    </SolutionWrap>
}

const SolutionWrap = styled(StyledContainer)`
    // border: red solid 2px;
    position: relative;
    padding: 0 var(--safe-area-x-wrapper);
    background-color: #141414;
    ${
        mediaMax1024(`
            padding: 0 var(--safe-area-x-wrapper);
        `)
    }
`

const SolutionContentWrap = styled(StyledContainer)`
    border-radius: 9px;
    background-color: #1F1F1F;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: var(--safe-area-x-wrapper);
    overflow: hidden;
    ${
        mediaMax1024(`
        // padding: 40px var(--safe-area-x-content);
        flex-direction: column;
        `)
    }
    `
    
    const MenuBoxWrap = styled(StyledContainer)`
    // border: red 2px solid;
    // padding: 70px 20px 20px 70px;
    padding: 70px calc(var(--safe-area-x-content) - var(--safe-area-x-wrapper));
    
    box-sizing: border-box;
    
    ${
        mediaMax1024(`
            padding: 40px 0 0;
            flex-direction: column;
        `)
    }
    `
    
    const SvgIconWrap = styled(IconCom)`
    width: 16px;
    height: 16px;
    margin-right: 10px;
    
    rect, path {
        fill: #767A7F;
        transition: all linear 0.2s;
    }
    `
    const MenuTextWrap = styled(StyledContainer)`
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #767A7F;
    `
    
    const MenuItemWrap = styled(Row)<{$active: boolean}>`
    // border: red 2px solid;
    margin-bottom: 26px;
    cursor:pointer;
    
    &:hover {
        ${SvgIconWrap}, ${MenuTextWrap} {
            color: #DFE0E2;
            rect, path {
                fill: #DFE0E2;
            }
        }
    }
    
    ${
        ({$active}) => $active ? css`
        ${SvgIconWrap}, ${MenuTextWrap} {
            color: #FFFFFF;
            rect, path {
                fill: #FFFFFF;
            }
        }
        `:css``
    }
    `
    
    const DetailBoxWrap = styled(StyledContainer)`
    // border: red 2px solid;
    border-radius: 9px;
    background-color: #0A0A0A;
    // padding: 70px 70px 70px 50px;
    padding: 70px calc(var(--safe-area-x-content) - var(--safe-area-x-wrapper));
    flex: 1;
    position: relative;
    height: 740px;

    box-sizing: border-box;

    &::before {
        content: '';
        display: block;
        height: 270px;
        background-image: url(${leftDotsImg});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: -114px;
        width: 114px;
        transform: translate(0, -50%);
    }

    ${
        mediaMax1024(`
            // padding: 30px calc(var(--safe-area-x-content) - var(--safe-area-x-wrapper));
            padding: 30px 16px;
            &::before {
                display: none;
            }
            min-height: 640px;
        `)
    }
`

const DetailTitleWrap = styled(StyledContainer)`
    font-size: 30px;
    line-height: 1;
    font-weight: 300;
`

const DetailSubTitleWrap = styled(StyledContainer)`
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    font-family: RoobertRegular;
`

const DetailSoonTitleWrap = styled(StyledContainer)`
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    font-family: RoobertRegular;
    color: #767A7F;
`

// 标签部分
const DetailTagBoxWrap = styled(StyledContainer)`
    display: flex;
    flex-wrap: wrap;
    width: 20vw;

    ${
        mediaMax1024(`
            width: 60vw;
        `)
    }
`

const DetailTagWrap = styled(StyledContainer)`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    font-family: RoobertRegular;
    padding: 6px;
    border-radius: 6px;
    background-color: #27069D;
    display: inline-flex;

    margin-right: 6px;
    margin-bottom: 6px;

    cursor: pointer;

    &:hover {
        background-color: #4D1DF7;
    }
`

// right box 
const DetailRightBox = styled(Column)`
    margin-top: 10px;
    align-items: flex-end;

    ${
        mediaMax1024(`
            align-items: flex-start;
        `)
    }
`

const DetailDescWrap = styled(StyledContainer)`
    width: 54%;
    font-size: 22px;
    line-height: 1.1;
    font-weight: 500;

    text-align: right;

    ${
        mediaMax1024(`
            width: 100%;
            text-align: left;
        `)
    }
`

// 
const NormalBoxWrap = styled(StyledContainer)`
    ${
        mediaMax1024(`
            display: none !important;
        `)
    }
`

const Media1024BoxWrap = styled(StyledContainer)`
    display: none !important;
    ${
        mediaMax1024(`
            display: block !important;
        `)
    }
`

const BtnListWrap = styled(Row)`
    // border: red 2px solid;
    margin-bottom: 34px;
    width: calc(100vw - 24px);
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        display: none;
    }
    `
    
const MenuBtnWrap = styled(MenuItemWrap)<{$active: boolean}>`
    // border: red 2px solid;
    padding: 14px 16px;
    border-radius: 9px;
    background-color: #292929;
    white-space: nowrap;
    margin-right: 6px;
    margin-bottom: 0;
    ${
        ({$active}) => $active ? css`
            background-color: #0a0a0a;
        `:css``
    }
`


const TipWrap = styled(Row)`
    padding: 8px;
    background-color: #000000;
    border: 1px solid #292929;
    border-radius: 6px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
`
export default SolutionsCom