import { Column, Row } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled from "styled-components"

import EarthVideo from 'assets/videos/earth.mp4'
import { ReactComponent as ArrowRightSvg } from 'assets/svgs/arrow_right.svg'
import { mediaMax1024 } from "./CommonStyle"

const LiquidityCom = () => {
    return <OutBoxWrap>
        <ContentBoxWrap>
            <VideoBoxWrap>
                <video  autoPlay loop muted src={EarthVideo}></video>
            </VideoBoxWrap>
            <StyledContainer $width="3vw"></StyledContainer>
            <DescribeWrap>
                <span>Dive into billions of cross-chain liquidity</span>
                <p>Fuel your project's potential with Chainge’s APIs – the catalyst for seamless interoperability and cross-chain swaps. Elevate to a full fledged multichain DEX in days and unlock a world of possibilities.</p>
                {/* <StyledContainer>
                    <CardBtnWrap>
                        Read more
                        <ArrowRightSvgWrap></ArrowRightSvgWrap>
                    </CardBtnWrap>
                </StyledContainer> */}
            </DescribeWrap>

        </ContentBoxWrap>
    </OutBoxWrap>
}


const OutBoxWrap = styled(StyledContainer)`
    position: relative;
    padding: 30px var(--safe-area-x-wrapper);
    background-color: #141414;

    ${
        mediaMax1024(`
            padding: 20px var(--safe-area-x-wrapper);
        `)
    }
`

const ContentBoxWrap = styled(Row)`
    width: 100%;
    padding: 200px var(--safe-area-x-content);
    background-color: #0A0A0A;
    box-sizing: border-box;
    border-radius: 9px;

    ${
        mediaMax1024(`
            flex-direction: column;
            padding: 30px var(--safe-area-x-content);
        `)
    }
`

const VideoBoxWrap = styled(StyledContainer)`
    width: max(20vw, 20vh);
    height: max(20vw, 20vh);
    &>video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${
        mediaMax1024(`
            width: 90vw;
            height: 90vw;
        `)
    }
`

const DescribeWrap = styled(Column)`
    width: 40vw;

    &>span {
        font-size: max(40px, calc(30px + 0.7vw));
        line-height: 1.1;
    }

    &>p {
        font-size: max(22px, calc(18px + 0.3vw));
        line-height: 1.1;
        color: #767A7F;
        font-weight: 400;
        font-family: RoobertRegular;
    }


    ${
        mediaMax1024(`
            width: 100%;
        `)
    }
`

const ArrowRightSvgWrap = styled(ArrowRightSvg)`
    margin-left: 6px;
`

const CardBtnWrap = styled(Row)`
    box-sizing: border-box;
    padding: 8px;
    border-radius: 6px;
    background-color: #1F1F1F;
    font-size: max(14px, calc(10px + 0.2vw));
    color: #A0A3A7;
    display: inline-flex;

    cursor: pointer;

    &:hover {
        background-color: #F2F2F2;
        color: #0A0A0A;
        ${ArrowRightSvgWrap} {
            rect, path {
                fill: #0A0A0A;
            }
        }
    }
`

export default LiquidityCom