import { Navigate, Route, Routes } from "react-router-dom"
import Home from "../pages/Home"
import MainLayout from "Layouts/MainLayout"

const Routers = () => {
    return <Routes>
        <Route path="" element={<MainLayout></MainLayout>}>
            <Route path="/" element={<Home></Home>}></Route>
            <Route path="*" element={<Navigate to="/" replace={true}></Navigate>}></Route>
        </Route>
    </Routes>
}

export default Routers