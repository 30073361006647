import StyledContainer from "style/styledContainer"
import styled from "styled-components"
import HeroCom from "./components/HeroCom"
import StatsCom from "./components/StatsCom"
import VideoCom from "./components/VideoCom"
import SolutionsBarCom from "./components/SolutionsBarCom"
import SolutionsCom from "./components/SolutionsCom"
import DownloadCom from "./components/DownloadCom"
import ScrollCardCom from "./components/ScrollCardCom"
import LiquidityCom from "./components/LiquidityCom"
import HubWinCom from "./components/HubWinCom"

const HomePage = () => {
  return <HomePageWrap>
    <HeroCom></HeroCom>
    <StatsCom></StatsCom>
    <VideoCom></VideoCom>
    <SolutionsBarCom></SolutionsBarCom>

    <div id="solution_flag"></div>
    <SolutionsCom></SolutionsCom>
    <DownloadCom></DownloadCom>

    <div id="ecosystem_flag"></div>
    <ScrollCardCom></ScrollCardCom>

    <div id="developers_flag"></div>
    <LiquidityCom></LiquidityCom>
  </HomePageWrap>
}

const HomePageWrap = styled(StyledContainer)`

`
export default HomePage