import StyledContainer from "style/styledContainer"
import styled from "styled-components"
import { motion } from "framer-motion"
import { cubicBezier } from "framer-motion"

import heroImg from "assets/images/hero_img.png"
import { ReactComponent as SwapSvg } from 'assets/svgs/swap.svg'
import { ReactComponent as MediumSvg } from 'assets/svgs/medium.svg'
import { ReactComponent as ArrowRightSvg } from 'assets/svgs/arrow_right.svg'

import { Row } from "style/preset"
import IconCom from "components/IconCom"
import AnimateCom from "components/AnimateCom"
import { mediaMax1024 } from "./CommonStyle"
import useUnits from "hooks/useUnits"


const HeroCom = () => {
    const { openBrowserByUrl } = useUnits()

    return <HeroWrap>
        <ImgWrapper>
            <AnimateImageWrap src={heroImg} 
                initial={{opacity: 0, scale: '95%'}}
                animate={{opacity: 1, scale: '100%'}}
                transition={{
                    duration: 1.4,
                    ease: cubicBezier(0.37, 0.08, 0.49, 1.1)
                }}
            ></AnimateImageWrap>
        </ImgWrapper>
        <MaskCom>
            <RightBoxWrap>
                <AnimateCom delay={0.6}>
                    <PageTitleWrap $mr="10%" className="spotlight">The Cryptoverse is a journey.<br/>Own it with Chainge.</PageTitleWrap>
                </AnimateCom>
                <GroupY>
                    <AnimateCom delay={0.8}>
                        <PageSecondTitleWrap>Find. Bridge. Store. Swap.</PageSecondTitleWrap>
                    </AnimateCom>
                    <AnimateCom delay={1}>
                        <PageThreeTitleWrap>And a dash more than others, because we can.</PageThreeTitleWrap>
                    </AnimateCom>
                </GroupY>
                <AnimateCom delay={1.2}>
                    <SwapBtnWrap onClick={() => openBrowserByUrl('https://dapp.chainge.finance')}>
                        <IconCom Svg={SwapSvg} size="max(22px, calc(11px + 0.7vw))"></IconCom>
                        <StyledContainer $ml="12px" $fontSize="max(16px, calc(8px + 0.5vw))" $lineHeight="1" $fontWeight5>Start trading</StyledContainer>
                        <ArrowRightSvgWrap></ArrowRightSvgWrap>
                    </SwapBtnWrap>
                </AnimateCom>
            </RightBoxWrap>
            <LeftBoxWrap>
                <AnimateCom delay={1.4}>
                    <TipWarnWrap onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/chainge-a-transformative-leap-forward-in-decentralized-finance-e17df613f418')}>
                        <StyledContainer style={{whiteSpace: 'nowrap'}} $fontSize="max(14px, calc(7px + 0.4vw))" $padding="8px 12px" $borderRadius="9px" $backgroundColor="#141414">Dec 20</StyledContainer>
                        <StyledContainer $ml="16px" $mr="26px" $fontSize="max(12px, calc(6px + 0.3vw))" $lineHeight="1.1">A transformative leap forward in <br/>decentralized finance</StyledContainer>
                        <IconCom Svg={MediumSvg} size="max(16px, calc(8px + 0.5vw))"></IconCom>
                    </TipWarnWrap>
                </AnimateCom>
            </LeftBoxWrap>
        </MaskCom>
    </HeroWrap>
}

const HeroWrap = styled(StyledContainer)`
    position: relative;
	display: flex;
    // min-height: 641px;
    min-height: max( calc((100vw - var(--safe-area-x-wrapper)) / 1340 * 641), 641px);
    ${
        mediaMax1024(`
            padding: 0 8px;
        `)
    }
`

const MaskCom = styled(StyledContainer)`
    // position
	width: 100%;

    // layout
	display: flex;
	flex-direction: column;
    justify-content: space-between;
	padding: calc(var(--ratio) * 72) var(--safe-area-x);
	gap: calc(var(--ratio) * 40);

    // style
	border-radius: calc(var(--ratio) * 24);
`

const AnimateImageWrap = styled(motion.img)`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: calc(var(--ratio) * 24);
`

const ImgWrapper = styled(StyledContainer)`
    // poition
    position: absolute;
    left: calc(var(--ratio) * 16);
    right: calc(var(--ratio) * 16);
    top: 0;
    bottom: 0;

    // layout
    display: inline-flex;
`


const RightBoxWrap = styled(StyledContainer)`
    // layout
    display: flex;
    flex-direction: column;
    gap: calc(var(--ratio) * 40);
`

const GroupY = styled(StyledContainer)`
    display: inline-flex;
	flex-direction: column;
	gap: calc(var(--ratio) * 8);
`

const PageTitleWrap = styled(StyledContainer)`
    font-size: calc(var(--ratio) * 48);
    font-weight: 700;
    line-height: 1.2;
    font-family: RoobertBold;
    ${
        mediaMax1024(`
            font-size: 30px;
        `)
    }
`

const PageSecondTitleWrap = styled(StyledContainer)`
    line-height: 1;
    font-family: RoobertSemiBold;
    font-size: calc(var(--ratio) * 22);
    font-weight: 600;

    // margin-top: 40px;

    ${
        mediaMax1024(`
            font-size: 18px;
        `)
    }
`

const PageThreeTitleWrap = styled(StyledContainer)`
    font-size: calc(var(--ratio) * 16);
    font-weight: 500;
    line-height: 1;
    // margin-top: 10px;
`

const ArrowRightSvgWrap = styled(ArrowRightSvg)`
    width: max(14px, calc(7px + 0.2vw));
    height: max(14px, calc(7px + 0.2vw));
    padding-left: 0px;
    transition: all linear 0.2s;
    opacity: 0;
    rect, path {
        transition: all linear 0.2s;
        fill: #ffffff;
    }
`

const SwapBtnWrap = styled(Row)`
	font-size: calc(var(--ratio) * 16);
	font-weight: 600;
	background: #4d1df7;
	border-radius: calc(var(--ratio) * 8);
	padding: calc(var(--ratio) * 12) calc(var(--ratio) * 20);
	align-self: start;
    cursor: pointer;
    max-width: fit-content;

    &:hover {
        ${ArrowRightSvgWrap} {
            padding-left: 24px;
            opacity: 1;
        }
    }
`

// 
const LeftBoxWrap = styled(StyledContainer)`
    text-align: right;
`

const TipWarnWrap = styled(Row)`
    border: 1px solid #1F1F1F;
    border-radius: 9px;
    background-color: #0A0A0A;
    
    padding: 14px 16px;
    display: inline-flex;

    text-align: left;
    
    cursor: pointer;

    &:hover {
        box-shadow: 0px 6px 14px -6px rgba(187, 191, 202, 0.12);
    }
`

export default HeroCom