import StyledContainer from "style/styledContainer"
import styled from "styled-components"
import { motion } from "framer-motion"
import { cubicBezier } from "framer-motion"

import { ReactComponent as LogoSvg } from 'assets/svgs/logo.svg'
import { ReactComponent as HubBtnSvg } from 'assets/svgs/hub_btn.svg'
import { ReactComponent as IcnMenuSvg } from 'assets/svgs/icn_menu.svg'
import { ReactComponent as HubBtnCloseSvg } from 'assets/svgs/hub_btn_close.svg'
import { RowCenter } from "style/preset"
import NavBarCom from "./NavBarCom"
import { mediaMax1024 } from "pages/Home/components/CommonStyle"
import MobileNavCom from "./MobileNavCom"
import { useAppDispatch, useAppSelector } from "hooks/redux/hooks"
import { setOpenHubMenu, setOpenMenu } from "hooks/redux/slices/appSlice"
import { useCallback, useEffect, useState } from "react"
import HubWinCom from "pages/Home/components/HubWinCom"

const HeaderCom = () => {
    const { openMenu, openHubMenu } = useAppSelector((state) => state.app)
    const [ tempOpenHubMenu, setTempOpenHubMenu ] = useState(true) 
    const dispatch = useAppDispatch()
    const [tempOpenMenu, setTempOpenMenu] = useState(true)
    useEffect(() => {
        if(!openMenu) {
            setTimeout(() => {
                setTempOpenMenu(false)
            }, 500);
        } else {
            setTempOpenMenu(true)
        }
    }, [openMenu])

    const fnOpenHubMenu = useCallback(() => {
        if(openHubMenu) {
            setTempOpenHubMenu(false)
            setTimeout(() => {
                dispatch(setOpenHubMenu(false))
            }, 500);
        } else {
            setTempOpenHubMenu(true)
            dispatch(setOpenHubMenu(true))
        }
    }, [dispatch, openHubMenu])

    return <StyledContainer>
        <AnimateHeaderWrap
            initial={{ top: '-16px', opacity: 0 }}
            animate={{ top: '0px', opacity: 1 }}
            transition={{
                duration: 0.8,
                delay: 2,
                ease: cubicBezier(0.37, 0.08, 0.49, 1.1)
            }}
        >
            <LeftWrap>
                <LogoSvgWrap></LogoSvgWrap>
            </LeftWrap>

            <NavBarCom></NavBarCom>

            <RightWrap>
                <HubBtnBoxWrap onClick={fnOpenHubMenu}>
                    {
                        !openHubMenu && <HubBtnSvgWrap></HubBtnSvgWrap>
                    }
                    {
                        openHubMenu && <HubBtnCloseSvgWrap></HubBtnCloseSvgWrap>
                    }
                </HubBtnBoxWrap>
                {
                    openHubMenu && <HubWinCom open={tempOpenHubMenu} onClose={fnOpenHubMenu}></HubWinCom>
                }
                <IcnMenuSvgWrap onClick={() => dispatch(setOpenMenu(true))}></IcnMenuSvgWrap>
            </RightWrap>
        </AnimateHeaderWrap>
        <StyledContainer $height="98px"></StyledContainer>
        {
            tempOpenMenu && <MobileNavCom></MobileNavCom>
        }
    </StyledContainer>
}

const HeaderWrap = styled(StyledContainer)`
    height: 98px;
    background-color: #0A0A0A;
    padding: 0 var(--safe-area-x);
    box-sizing: border-box;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;
    justify-content: space-between;

    display: flex;
    align-items: center;

    ${
        mediaMax1024(`
            padding: 0 24px;
        `)
    }
`
const AnimateHeaderWrap = styled(motion(HeaderWrap))`
    display: flex;
    justify-content: space-between;
`;

const LeftWrap = styled(StyledContainer)`
    width: 180px;
    cursor: pointer;
`

const RightWrap = styled(StyledContainer)`
    width: 180px;
    display: flex;
    justify-content: flex-end;
`

export const LogoSvgWrap = styled(LogoSvg)``

const HubBtnSvgWrap = styled(HubBtnSvg)`
    width: 48px;
    height: 48px;
    transition: all linear 0.2s;
    circle, rect, path {
        transition: all linear 0.2s;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const HubBtnCloseSvgWrap = styled(HubBtnCloseSvg)``

const HubBtnBoxWrap = styled(RowCenter)`
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    z-index: 103;

    &:hover {
        transform: rotate(-90deg);
    }

    ${HubBtnSvgWrap} {
        &:hover {
            width: 40px;
            height: 40px;
            circle {
                fill: #FAFAFA;
            }
            rect, path {
                fill: #0A0A0A;
            }
        }
    }

    display: block;
    ${
        mediaMax1024(`
            display: none !important;
        `)
    }
`

const IcnMenuSvgWrap = styled(IcnMenuSvg)`
    display: none;
    ${
        mediaMax1024(`
            display: block !important;
        `)
    }
`

export default HeaderCom