import { Column, Row } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled, { css } from "styled-components"

import dappImg from 'assets/images/dapp_tag_white.png'
import dappHoverImg from 'assets/images/dapp_tag_white_hover.png'

import iosImg from 'assets/images/ios_tag_white.png'
import iosHoverImg from 'assets/images/ios_tag_white_hover.png'

import googleImg from 'assets/images/google_tag_white.png'
import googleHoverImg from 'assets/images/google_tag_white_hover.png'

import { ReactComponent as ArrowDownSvg } from 'assets/svgs/arrow_down.svg'

import { ReactComponent as IcnTgSvg } from 'assets/svgs/icn_tg.svg'
import { ReactComponent as IcnTwitterSvg } from 'assets/svgs/icn_twitter.svg'
import { ReactComponent as IcnMedimSvg } from 'assets/svgs/icn_medim.svg'
import { ReactComponent as IcnYoutubSvg } from 'assets/svgs/icn_youtub.svg'
import { ReactComponent as IcnLinkTreeSvg } from 'assets/svgs/icn_linktree.svg'

import { useCallback, useEffect, useState } from "react"
import { LogoSvgWrap } from "./HeaderCom"
import IconCom from "components/IconCom"
import useUnits from "hooks/useUnits"
import { useAppDispatch, useAppSelector } from "hooks/redux/hooks"
import { setCurSolution } from "hooks/redux/slices/appSlice"
import { SoonTip } from "./NavBarCom"
import { mediaMax1024 } from "pages/Home/components/CommonStyle"

const menuList = [
    {
        title: 'Solutions',
        child: [
            {
                title: 'Trading',
            },
            {
                title: 'Wallet',
            },
            {
                title: 'Coin blinks',
            },
            {
                title: 'Natural Language',
            },
            {
                title: 'Hardware wallet',
            },
            {
                title: 'Credit Card',
            },
            {
                title: 'Earn with Chainge',
            }
        ]
    },
    {
        title: 'Ecosystem',
        child: [
            {
                title: 'Network',
            },
            {
                title: 'DEXs',
            },
            {
                title: 'Aggregators',
            },
            {
                title: 'Web3 Browsers',
            },
            {
                title: 'Wallets',
            },
            {
                title: 'Become a Partner',
            }
        ]
    },
    {
        title: 'Developers',
        child: [
            {
                title: 'API',
            },
            {
                title: 'Bridge Infrastructure',
            }
        ]
    },
    {
        title: 'Docs',
        child: [
            {
                title: 'Tech',
                url: 'https://www.fusion.org/tech/dcrm'
            },
            {
                title: 'Router',
                url: 'https://www.cryptoeq.io/research/cross-chain-liquidity'
            },
            {
                title: 'Analysis',
                url: 'https://www.blocmates.com/protocol-focus/chainge-finance-the-future-of-decentralized-exchanges'
            },
            {
                title: 'Github',
                url: 'https://github.com/ChaingeFinance'
            }
        ]
    },
    {
        title: 'Governance',
        child: [
            {
                title: 'DAO',
                soon: true
            },
            {
                title: 'Tokenomics 2.0',
                soon: true
            },
        ]
    },
    {
        title: 'Learn more',
        child: [
            {
                title: 'Audits',
                url: 'https://skynet.certik.com/projects/chaingefinance'
            },
            {
                title: 'Support',
                url: 'https://t.me/chaingefinance'
            },
            {
                title: 'Media Kit',
                soon: true
            },
            {
                title: 'Contact',
                email: 'official@chainge.finance'
            },
        ]
    }
]

const contactusList = [
    {
        icon: IcnTgSvg,
        flag: 'tg'
    },
    {
        icon: IcnTwitterSvg,
        flag: 'twitter'
    },
    {
        icon: IcnMedimSvg,
        flag: 'medium'
    },
    {
        icon: IcnYoutubSvg,
        flag: 'youtube'
    },
    {
        icon: IcnLinkTreeSvg,
        flag: 'linktree'
    }
]

const FooterCom = () => {
    const { isMobile } = useAppSelector((state) => state.app)
    const { openBrowserByDownload, contactUs, openBrowserByUrl, scrollTopByFlag} = useUnits()
    const [dappImgUrl, setDappImgUrl] = useState(dappImg)
    const [iosImgUrl, setIosImgUrl] = useState(iosImg)
    const [googleImgUrl, setGoogleImgUrl] = useState(googleImg)
    const [foldList, setFoldList] = useState<string[]>([])

    const dispatch = useAppDispatch()

    const fnSubNavClick = useCallback((subItem: any, item: any) => {
        if(!subItem) {
            if(isMobile) {
                const tempFold = [...foldList]
                const index = tempFold.findIndex(foldItem => foldItem === item.title)
                if(index > -1) {
                    tempFold.splice(index, 1)
                } else {
                    tempFold.push(item?.title)
                }
                setFoldList(tempFold)
            }
            return
        }
        if(item.title === 'Solutions' && subItem) {
            dispatch(setCurSolution(subItem.title))
            scrollTopByFlag('solution_flag')
        }
        if(item.title === 'Ecosystem' && subItem) {
            scrollTopByFlag('ecosystem_flag')
        }
        if(item.title === 'Developers') {
            scrollTopByFlag('developers_flag')
        }

        if(subItem && subItem?.url) {
            openBrowserByUrl(subItem.url)
        }
        if(subItem && subItem?.email) {
            document.location = `mailto:${subItem?.email}?subject=Hi,Chainge&body=Hi`
        }
    }, [isMobile, foldList, dispatch, scrollTopByFlag, openBrowserByUrl])

    const openDapp = useCallback((fromChain: string, toChain: string, fromToken: string, toToken: string) => {
        let url = `https://dapp.chainge.finance/?fromChain=${fromChain}&toChain=${toChain}&fromToken=${fromToken}&toToken=${toToken}`
        openBrowserByUrl(url)
    }, [openBrowserByUrl])

    useEffect(() => {
        if(!isMobile) {
            setFoldList([])
        }
    }, [isMobile])

    return <ContentBoxWrap>
        <DownloadBoxWrap $justifyContent="space-between">
            <StyledContainer $fontSize="max(20px, calc(10px + 0.5vw))" $lineHeight="1" $fontWeight6 $roobertSemiBold>Change the way you interact with the cryptoverse</StyledContainer>
            <StyledContainer $height="20px"></StyledContainer>
            <Row>
                <TagImgWrap src={dappImgUrl} $width="124px" $height="35px" onClick={() => openBrowserByDownload('dapp')} onMouseOver={() => setDappImgUrl(dappHoverImg)} onMouseLeave={() => setDappImgUrl(dappImg)}></TagImgWrap>
                <TagImgWrap src={iosImgUrl} $width="125px" $height="35px" onClick={() => openBrowserByDownload('ios')}  onMouseOver={() => setIosImgUrl(iosHoverImg)} onMouseLeave={() => setIosImgUrl(iosImg)}></TagImgWrap>
                <TagImgWrap src={googleImgUrl} $width="118px" $height="35px" onClick={() => openBrowserByDownload('google')} onMouseOver={() => setGoogleImgUrl(googleHoverImg)} onMouseLeave={() => setGoogleImgUrl(googleImg)}></TagImgWrap>
            </Row>
        </DownloadBoxWrap>
        <FooterWrap>
            <FooterNavWrap>
                {
                    menuList.map((item, index) => 
                        <NavListWrap key={index}>
                            <NavHeaderWrap onClick={() => fnSubNavClick(null, item)}>
                                <span>{item.title}</span>
                                <ArrowDownSvgWrap $isFold={foldList.includes(item.title)}></ArrowDownSvgWrap>
                            </NavHeaderWrap>
                            {
                                !foldList.includes(item.title) && item.child.map((subItem: any, subIndex) => 
                                    <NavItemWrap $pointer $soon={subItem?.soon} onClick={() => fnSubNavClick(subItem, item)} key={subIndex}>
                                        {subItem.title}
                                        {
                                            subItem?.soon && <SoonTip>soon</SoonTip>
                                        }
                                    </NavItemWrap>
                                )
                            }
                        </NavListWrap>
                    )
                }
                
            </FooterNavWrap>
            <StyledContainer $height="70px"></StyledContainer>
            <ContactUsWrap>
                <LogoSvgWrap></LogoSvgWrap>
                <ContactUsListWrap>
                    {
                        contactusList.map((item, index) => <StyledContainer onClick={() => contactUs(item.flag)} key={index}>
                                <IconCom Svg={item.icon}></IconCom>
                            </StyledContainer>
                        )
                    }
                    
                </ContactUsListWrap>
            </ContactUsWrap>
            <StyledContainer $height="60px"></StyledContainer>

            <DescribeBoxWrap>
                <Column $width="50vw">
                    <StyledContainer $fontSize="14px" $lineHeight="1" $fontWeight5>© 2024 Chainge</StyledContainer>
                    <StyledContainer $mt="8px" $fontSize="14px" $lineHeight="1" $fontWeight4 $roobertRegular>All rights reserved. Version 2.0</StyledContainer>
                    <StyledContainer $mt="18px" $fontSize="12px" $lineHeight="16px" $color="#767A7F" $fontWeight3 $roobertLight>More than a DEX, more than a bridge, more than a wallet; we're a tech driven one-stop shop DeFi app, dedicated to shaping the future of decentralized finance (DeFi). Our journey began with a simple yet powerful vision: to empower individuals globally with open and accessible financial tools.</StyledContainer>
                    <StyledContainer $mt="18px" $fontSize="12px" $lineHeight="16px" $color="#767A7F" $fontWeight3 $roobertLight>Our vision is to create a borderless and equitable crypto landscape, where DeFi services are accessible globally, innovation knows no bounds, and robust tech is the driving force behind it all.</StyledContainer>
                </Column>
                <StyledContainer $height="20px"></StyledContainer>
                <Column $textAlign="right">
                    <StyledContainer onClick={() => openDapp('BNB', 'BNB', 'USDT', 'BTC')} $pointer $mb="10px" $fontSize="12px" $lineHeight="16px" $fontWeight4 $roobertRegular>Swap USDT to BTC</StyledContainer>
                    <StyledContainer onClick={() => openDapp('ETH', 'BNB', 'ETH', 'BTC')} $pointer $mb="10px" $fontSize="12px" $lineHeight="16px" $fontWeight4 $roobertRegular>Swap ETH to BTC</StyledContainer>
                    <StyledContainer onClick={() => openDapp('ETH', 'ETH', 'ETH', 'USDT')} $pointer $mb="10px" $fontSize="12px" $lineHeight="16px" $fontWeight4 $roobertRegular>Swap ETH to USDT</StyledContainer>
                    <StyledContainer onClick={() => openDapp('BNB', 'ETH', 'USDC', 'USDC')} $pointer $mb="10px" $fontSize="12px" $lineHeight="16px" $fontWeight4 $roobertRegular>Bridge USDC to Ethereum</StyledContainer>
                    <StyledContainer onClick={() => openDapp('FSN', 'FSN', 'USDT', 'CHNG')} $pointer $mb="10px" $fontSize="12px" $lineHeight="16px" $fontWeight4 $roobertRegular>Buy CHNG with USDT</StyledContainer>
                    <StyledContainer onClick={() => openDapp('FSN', 'FSN', 'USDC', 'CHNG')} $pointer $fontSize="12px" $lineHeight="16px" $fontWeight4 $roobertRegular>Buy CHNG with USDC</StyledContainer>
                </Column>
            </DescribeBoxWrap>
        </FooterWrap>
        <StyledContainer $height="50px"></StyledContainer>
    </ContentBoxWrap>
}

const ContentBoxWrap = styled(StyledContainer)`
    background-color: #141414;
    padding: 30px 24px;
    box-sizing: border-box;
    ${
        mediaMax1024(`
            padding: 20px 8px;
        `)
    }
`

const DownloadBoxWrap = styled(Row)`
    padding: 42px var(--safe-area-x-content);
    border-radius: 9px;
    background-color: #4D1DF7;

    ${
        mediaMax1024(`
            padding: 30px var(--safe-area-x-content);
            flex-direction: column;
            align-items: flex-start;
        `)
    }
`

const TagImgWrap = styled.img<{$width: string, $height: string}>`
    cursor: pointer;
    margin-right: 6px;
    &:last-child {
        margin: 0;
    }
    ${
        ({$width, $height}) => css`
            width: ${$width};
            height: ${$height};
        `
    }

    ${
        mediaMax1024(`
            width: calc((100% - 12px) / 3);
            height: calc(((100% - 12px) / 3) * 35 / 125);
        `)
    }
`

const FooterWrap = styled(StyledContainer)`
    padding: 70px 95px 0;
    padding: 70px var(--safe-area-x-content);

    ${
        mediaMax1024(`
            // padding: 70px 16px 0;
        `)
    }
`

const FooterNavWrap = styled(Row)`
    align-items: flex-start;
    justify-content: space-between;

    ${
        mediaMax1024(`
            flex-direction: column;
        `)
    }
`

const NavListWrap = styled(Column)`
    ${
        mediaMax1024(`
            width: 100%;
            margin-bottom: 9px;
        `)
    }
`

const ArrowDownSvgWrap = styled(ArrowDownSvg)<{$isFold: boolean}>`
    display: none;
    transform: rotateZ(0deg);
    transition: all linear 0.2s;
    ${
        mediaMax1024(`
            display: block;
        `)
    }
    ${
        ({$isFold}) => $isFold ? css`
            transform: rotateZ(180deg);
        `:css``
    }
`

const NavHeaderWrap = styled(Row)`
    margin-bottom: 24px;
    justify-content: space-between;
    cursor: pointer;
    &>span {
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        font-family: RoobertSemiBold;
    }
`

const NavItemWrap = styled(Row)<{$soon: boolean}>`
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    font-family: RoobertRegular;
    color: ${({$soon}) => $soon ? '#3D3D3D' : '#767A7F'};
    margin-bottom: 16px;

    ${
        ({$soon}) => $soon ? css`
        
        ` : css`
            &:hover {
                color: #FFFFFF;
            }
        `
    }
`

// 联系我们
const ContactUsWrap = styled(Row)`
    justify-content: space-between;
`

const ContactUsListWrap = styled(Row)`
    &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        background-color: #1F1F1F;
        margin-left: 8px;
        width: 36px;
        height: 36px;

        cursor: pointer;

        &:hover {
            background-color: #333333 !important;
        }

        &>svg {
            rect, path {
                transition: all linear 0.2s;
            }
            &:hover {
                rect, path {
                    fill: white;
                }
            }
        }
    }
`

// 描述
const DescribeBoxWrap = styled(Row)`
    justify-content: space-between;
    ${
        mediaMax1024(`
            flex-direction: column;
            align-items: flex-start;
            &>div {
                text-align: left;
            }
        `)
    }
`

export default FooterCom