import { Column, ColumnCenter, Row } from "style/preset"
import StyledContainer from "style/styledContainer"
import styled, { css } from "styled-components"
import { ReactComponent as LogoSvg } from 'assets/svgs/logo_blue.svg'
import { ReactComponent as IcnCloseSvg } from 'assets/svgs/icn_close.svg'
import { ReactComponent as IcnMoreSvg } from 'assets/svgs/icn_more.svg'

import IconCom from "components/IconCom"
import { menuList } from "./NavBarCom"
import { AnimatePresence, motion } from "framer-motion"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "hooks/redux/hooks"
import { setCurSolution, setOpenMenu } from "hooks/redux/slices/appSlice"


import dappImg from 'assets/images/dapp_tag_white.png'
import dappHoverImg from 'assets/images/dapp_tag_white_hover.png'

import iosImg from 'assets/images/ios_tag_white.png'
import iosHoverImg from 'assets/images/ios_tag_white_hover.png'

import googleImg from 'assets/images/google_tag_white.png'
import googleHoverImg from 'assets/images/google_tag_white_hover.png'


import { ReactComponent as WebIconSvg } from 'assets/svgs/web_icon.svg'
import { ReactComponent as AppStoreIconSvg } from 'assets/svgs/appstore_icon.svg'
import { ReactComponent as GoogleIconSvg } from 'assets/svgs/googleplay_icon.svg'
import { ReactComponent as MediumIconSvg } from 'assets/svgs/medum_icon.svg'
import { mediaMax1024 } from "pages/Home/components/CommonStyle"
import useUnits from "hooks/useUnits"

const MobileNavCom = () => {
    const { openBrowserByDownload, scrollTopByFlag, openBrowserByUrl } = useUnits()
    const { openMenu  } = useAppSelector((state) => state.app)
    const dispatch = useAppDispatch()
    const [curIndex, setCurIndex] = useState(-1)
    const [curAciton, setCurAction] = useState('')
    const [oldAction, setOldAction] = useState('')
    const [isBack, setIsBack] = useState(false)
    const [showSubMenu, setShowSubMenu] = useState(false)
    const [loadMenu, setLoadMenu] = useState(false)

    const [bottomMenu, setBottomMenu] = useState(false)


    const fnCloseMenu = useCallback(() => {
        setLoadMenu(false)
        setBottomMenu(false)
        setTimeout(() => {
            dispatch(setOpenMenu(false))
        }, 500);
    }, [dispatch])

    useEffect(() => {
        if(!openMenu) {
            setBottomMenu(false)
            setLoadMenu(false)
        }
    }, [openMenu])

    const fnNavClick = useCallback((item: any, index: number) => {
       if(item.soon) {
            return
       }
       if(item.child && !item.child.length) {
            if(item.label === 'Developers') {
                scrollTopByFlag('developers_flag')

                fnCloseMenu()
            }
            return
       }
       if(curAciton) {
            setCurAction('')
            setOldAction(curAciton)
            setIsBack(true)
            setCurIndex(-1)
            setCountNum(0)
       } else {
            setCurAction(item.label)
            setCurIndex(index)
       }
    }, [curAciton, fnCloseMenu, scrollTopByFlag])

    const fnSubNavClick = useCallback((subItem: any, item: any) => {
        if(item.label === 'Solutions' && subItem) {
            dispatch(setCurSolution(subItem.label))
            scrollTopByFlag('solution_flag')
        }
        if(item.label === 'Ecosystem' && subItem) {
            scrollTopByFlag('ecosystem_flag')
        }
        if(item.label === 'Developers') {
            scrollTopByFlag('developers_flag')
        }

        if(subItem && subItem?.url) {
            openBrowserByUrl(subItem.url)
        }
        if(subItem.soon) {
            return
        }
        fnCloseMenu()
    }, [fnCloseMenu, dispatch, scrollTopByFlag, openBrowserByUrl])

    const checkOpenSub = useCallback((item: {[key: string]: any}) => {
        return !item.soon && item.child && item.child.length > 0
    }, [])

    const fnOneAnimationComplete = () => {
        setLoadMenu(true)
        setBottomMenu(true)
    }

    const [countNum, setCountNum] = useState(0)
    const fnTwoAnimationComplete = useCallback((index: number) => {
        if(curIndex <= -1) return
        if(curIndex === index) {
            setCountNum(countNum+1)
        }
    }, [countNum, curIndex])

    useEffect(()=>{
        if(countNum === 2) {
            setShowSubMenu(true)
        } else {
            setShowSubMenu(false)
        }
    }, [countNum])

    const getInitObj = useCallback((item: {[key: string]: any}) => {
        if(curAciton) {
            if(curAciton === item.label) {
                return { transform: 'translateY(0px)' }
            } else {
                return { opacity: 1, transform: 'translateX(0px)'}
            }
        } else {
            return {opacity: 0, transform: 'translateX(5px)'}
        }
    }, [curAciton])

    const getAnimateObj = useCallback((item: {[key: string]: any}, index: number) => {
        if(curAciton) {
            if(curAciton === item.label && checkOpenSub(item)) {
                let tempYNum = ''
                tempYNum = `-${index * 62}px`
                return { transform: `translateY(${tempYNum})` }
            } else {
                return { opacity: 0, transform: 'translateX(-5px)'}
            }
        } else {
            return {opacity: 1, transform: 'translateX(0px)'}
        }
    }, [checkOpenSub, curAciton])

    const getDelay = useCallback((item: {[key: string]: any}, index: number) => {
        if(curAciton) {
            if(curAciton === item.label && checkOpenSub(item)) {
                return 0.1 * (menuList.length - index + 1)
            } else {
                return 0.1 * (menuList.length - index - 1)
            }
        } else {
            if(isBack) {
                if(oldAction === item.label) {
                    return 0.01
                } else {
                    return 0.1 * (index + 2)
                }
            } else {
                return 0.1 * index
            }
        }
    }, [checkOpenSub, curAciton, isBack, oldAction])

    const getDuration = useCallback((item: {[key: string]: any}, index: number) => {
        if(curAciton) {
            if(curAciton === item.label && checkOpenSub(item)) {
                return 0.2 * index
            }
            return 0.2 
        } else {
            if(isBack) {
                if(oldAction === item.label) {
                    return 0.2 * index
                } else {
                    return 0.2
                }
            } else {
                return 0.2
            }
        }
    }, [checkOpenSub, curAciton, isBack, oldAction])


    const [dappImgUrl, setDappImgUrl] = useState(dappImg)
    const [iosImgUrl, setIosImgUrl] = useState(iosImg)
    const [googleImgUrl, setGoogleImgUrl] = useState(googleImg)

    const [openWin, setOpenWin] = useState(false)
    const [hubWin, setHubWin] = useState(false)

    const fnOpenHubBtn = () => {
        setLoadMenu(false)
        setBottomMenu(false)
        if(openWin) {
            setHubWin(true)
        }
    }

    const fnCloseHubWin = () => {
        setLoadMenu(true)
        setBottomMenu(true)
        setOpenWin(false)
        setHubWin(false)
    }

    const fnHubOpenStart = () => {
        setOpenWin(true)
    }

    return <AnimatePresence>
            {
                openMenu && <AnimateOutBoxWrap
                    initial={{height: '0vh'}}
                    animate={{height: '100vh'}}
                    transition={{
                        duration: 0.5,
                        ease: 'easeInOut'
                    }}
                    exit={{ height: "0vh" }}
                    onAnimationComplete={fnOneAnimationComplete}
                >
                    <MobileNavWrap>
                        <HeaderWrap $justifyContent="space-between">
                            <IconCom Svg={LogoSvg} height="45" width="131"></IconCom>
                            <StyledContainer onClick={fnCloseMenu}>
                                <IconCom Svg={IcnCloseSvg} size="18"></IconCom>
                            </StyledContainer>
                        </HeaderWrap>
                        <AnimatePresence>
                            {
                                loadMenu && <>
                                <StyledContainer $height="calc(70px + 56px)"></StyledContainer>
                                    {
                                        menuList.map((item, index) => 
                                            <AnimateWrap key={index}
                                                initial={ getInitObj(item) }
                                                animate={ getAnimateObj(item, index) }
                                                transition={{
                                                    duration: getDuration(item, index),
                                                    delay: getDelay(item, index),
                                                    ease: 'easeInOut'
                                                }}
                                                exit={{opacity: 0, transform: 'translateX(5px)'}}
                                                onAnimationComplete={() => fnTwoAnimationComplete(index)}
                                            >
                                                <NavItemWrap onClick={() => fnNavClick(item, index)} $delay={menuList.length - 1 - index} $active={item.label === curAciton} $soon={item.soon || false}>
                                                    <Row>
                                                        <Row>
                                                            <IcnBackSvgWrap $delay={menuList.length - 1 - index}></IcnBackSvgWrap>
                                                            <span>{item.label}</span>
                                                        </Row>
                                                        {
                                                            item.soon && <SoonTip>soon</SoonTip>
                                                        }
                                                    </Row>
                                                    {
                                                        checkOpenSub(item) && <IcnMoreSvgWrap></IcnMoreSvgWrap>
                                                    }
                                                </NavItemWrap>
                                                {
                                                    checkOpenSub(item) && showSubMenu && curAciton === item.label &&  item.child.map((subItem: any, subIndex) => <AnimateWrap key={subIndex}
                                                        initial={{opacity: 0, transform: 'translateX(10px)'}}
                                                        animate={{opacity: 1, transform: 'translateX(0px)'}}
                                                        transition={{
                                                            delay: 0,
                                                            duration: 0.3 * (item.child.length - 1 - subIndex)
                                                        }}
                                                    >
                                                            <SubNavItemWrap onClick={() => fnSubNavClick(subItem, item)} $soon={subItem?.soon || false}>
                                                                {subItem.label}
                                                                {
                                                                    subItem?.soon && <SoonTip>soon</SoonTip>
                                                                }
                                                            </SubNavItemWrap>
                                                        </AnimateWrap>
                                                    )
                                                }
                                            </AnimateWrap>
                                        )
                                    }
                                </>
                            }
                        </AnimatePresence>
                    </MobileNavWrap>

                    <AnimatePresence>
                        {
                            bottomMenu &&  <AnimateBottomHubWrap
                                initial={{bottom: '-805px'}}
                                animate={{bottom: '20px'}}
                                exit={{bottom: '-805px'}}
                                transition={{
                                    duration: 0.2,
                                    delay: 0.4
                                }}
                                onAnimationComplete={fnHubOpenStart}
                            >
                                <Row $justifyContent="space-between" $mb="40px" onClick={fnOpenHubBtn}>
                                    <StyledContainer $fontSize="22px" $lineHeight="26px" $fontWeight5>Chainge Hub<br/>in a glance</StyledContainer>
                                    <IconCom Svg={IcnMoreSvg} size="16"></IconCom>
                                </Row>

                                <Row>
                                    <TagImgWrap src={dappImgUrl} $width="125px" $height="35px" onClick={() => openBrowserByDownload('dapp')} onMouseOver={() => setDappImgUrl(dappHoverImg)} onMouseLeave={() => setDappImgUrl(dappImg)}></TagImgWrap>
                                    <TagImgWrap src={iosImgUrl} $width="125px" $height="35px" onClick={() => openBrowserByDownload('ios')}  onMouseOver={() => setIosImgUrl(iosHoverImg)} onMouseLeave={() => setIosImgUrl(iosImg)}></TagImgWrap>
                                    <TagImgWrap src={googleImgUrl} $width="124px" $height="35px" onClick={() => openBrowserByDownload('google')}  onMouseOver={() => setGoogleImgUrl(googleHoverImg)} onMouseLeave={() => setGoogleImgUrl(googleImg)}></TagImgWrap>
                                </Row>
                            </AnimateBottomHubWrap>
                        }
                        <AnimatePresence>
                            {
                                hubWin && <AnimateWrap
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    transition={{
                                        duration: 0.3,
                                        delay: 0.6
                                    }}
                                    exit={{opacity: 0}}
                                >
                                    <StyledContainer $height="calc(70px + 56px)"></StyledContainer>
                                    <HubWinWrap>
                                        <HubTitleWrap onClick={fnCloseHubWin}>
                                            <IcnBackSvgWrap $delay={0}></IcnBackSvgWrap>
                                            <span>Chainge Hub</span>
                                        </HubTitleWrap>
                                        <StyledContainer $height="30px"></StyledContainer>
                                        <ScrollBoxWrap>
                                            <StyledContainer $mb="12px" $fontSize="14px" $lineHeight="16px" $fontWeight4 $roobertRegular>Products</StyledContainer>
                                            <Row $justifyContent="space-between" $mb="40px">
                                                <BlockCardWrap onClick={() => openBrowserByDownload('dapp')}>
                                                    <IconCom Svg={WebIconSvg} size="20"></IconCom>
                                                    <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $m="12px 0 10px">Web dApp</StyledContainer>
                                                    <StyledContainer $fontSize="10px" $lineHeight="12px" $fontWeight4 $roobertRegular>Version 2.0 is live</StyledContainer>
                                                </BlockCardWrap>
                                                <BlockCardWrap onClick={() => openBrowserByDownload('ios')}>
                                                    <IconCom Svg={AppStoreIconSvg} size="20"></IconCom>
                                                    <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $m="12px 0 10px">iOS App</StyledContainer>
                                                    <StyledContainer $fontSize="10px" $lineHeight="12px" $fontWeight4 $roobertRegular>Version 2.0 soon</StyledContainer>
                                                </BlockCardWrap>
                                                <BlockCardWrap onClick={() => openBrowserByDownload('google')}>
                                                    <IconCom Svg={GoogleIconSvg} size="20"></IconCom>
                                                    <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5 $m="12px 0 10px">Android App</StyledContainer>
                                                    <StyledContainer $fontSize="10px" $lineHeight="12px" $fontWeight4 $roobertRegular>Version 2.0 soon</StyledContainer>
                                                </BlockCardWrap>
                                            </Row>

                                            <StyledContainer $mb="12px" $fontSize="14px" $lineHeight="16px" $fontWeight4 $roobertRegular>Ecosystem news</StyledContainer>
                                            <Block2CardWrap  onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/chainge-a-transformative-leap-forward-in-decentralized-finance-e17df613f418')}>
                                                <Row>
                                                    <IconCom Svg={MediumIconSvg} size="20"></IconCom>

                                                    <Column $ml="20px" $flex1="1">
                                                        <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5>Acquisition</StyledContainer>
                                                        <StyledContainer $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>A transformative leap forward in decentralized finance</StyledContainer>
                                                    </Column>
                                                </Row>
                                            </Block2CardWrap>
                                            <Block2CardWrap  onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/unveiling-xchng-a-blueprint-for-sustainability-and-growth-c0efb557b31f')}>
                                                <Row>
                                                    <IconCom Svg={MediumIconSvg} size="20"></IconCom>

                                                    <Column $ml="20px" $flex1="1">
                                                        <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5>XCHNG Tokenomics</StyledContainer>
                                                        <StyledContainer $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>XCHNG Tokenomics. A Blueprint for Sustainability and Growth</StyledContainer>
                                                    </Column>
                                                </Row>
                                            </Block2CardWrap>
                                            <Block2CardWrap  onClick={() => openBrowserByUrl('https://medium.com/@chainge-finance/embracing-2024-changes-discover-new-ways-to-earn-60471bdfaaf8')}>
                                                <Row>
                                                    <IconCom Svg={MediumIconSvg} size="20"></IconCom>

                                                    <Column $ml="20px" $flex1="1">
                                                        <StyledContainer $fontSize="16px" $lineHeight="18px" $fontWeight5>New Ways To Earn</StyledContainer>
                                                        <StyledContainer $fontSize="12px" $lineHeight="14px" $fontWeight4 $roobertRegular>Embracing 2024 Changes: Discover New Ways To Earn</StyledContainer>
                                                    </Column>
                                                </Row>
                                            </Block2CardWrap>
                                        </ScrollBoxWrap>
                                    </HubWinWrap>
                                </AnimateWrap>
                            }
                        </AnimatePresence>
                    </AnimatePresence>
                </AnimateOutBoxWrap>
            }
    </AnimatePresence>


}

const MobileNavWrap = styled(StyledContainer)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10;

    background-color: #4D1DF7;
`
const AnimateOutBoxWrap = styled(motion(MobileNavWrap))``

const HeaderWrap = styled(Row)`
    padding: 25px 24px 0;
    box-sizing: border-box;
    position: fixed;
    width: 100vw;
`

const IcnMoreSvgWrap = styled(IcnMoreSvg)`
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 1;
    transition: all linear 0.2s;
`

const IcnBackSvgWrap = styled(IcnMoreSvgWrap)<{$delay: number}>`
    opacity: 0;
    transform: rotateY(180deg);
    transition: all linear 0.2s ${({$delay}) => $delay * 0.1}s;
`

const NavItemWrap = styled(Row)<{$soon: boolean, $active: boolean, $delay: number}>`
    margin-bottom: 32px;
    padding: 0 24px;
    box-sizing: border-box;
    justify-content: space-between;

    span {
        position: relative;
        left: -16px;

        font-size: 30px;
        line-height: 1;
        font-weight: 500;
        color: ${({$soon}) => $soon ? '#987BFF': '#ffffff'};
        transition: all linear 0.2s ${({$delay}) => $delay * 0.1}s;
        padding-left: ${({$active}) => $active ? '52px' : '0px'}
    }

    ${
        ({$active}) => $active ? css`
            ${IcnMoreSvgWrap} {
                opacity: 0;
            }
            ${IcnBackSvgWrap} {
                opacity: 1;
            }
        `:css``
    }

`

const SoonTip = styled(StyledContainer)`
    padding: 4px 6px;
    line-height: 1 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: RoobertSemiBold;
    color: #27069D !important;
    background-color: #987BFF;
    border-radius: 12px;
    margin-left: 6px;
`
const AnimateWrap = styled(motion.div)``


const SubNavItemWrap = styled(Row)<{$soon: boolean}>`
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
    padding-left: 76px;
    color: ${({$soon}) => $soon ? '#987BFF': '#ffffff'};
`


const BottomHubWrap = styled(StyledContainer)`
    border-radius: 9px;
    background-color: #27069D;
    width: calc(100vw - 16px);

    position: absolute;
    bottom: 20px;
    left: 8px;

    padding: 30px 16px;
    box-sizing: border-box;
`
const AnimateBottomHubWrap = styled(motion(BottomHubWrap))``


const TagImgWrap = styled.img<{$width: string, $height: string}>`
    cursor: pointer;
    margin-right: 6px;
    &:last-child {
        margin: 0;
    }
    ${
        ({$width, $height}) => css`
            width: ${$width};
            height: ${$height};
        `
    }

    ${
        mediaMax1024(`
            width: calc((100% - 12px) / 3);
            height: calc(((100% - 12px) / 3) * 35 / 125);
        `)
    }
`

const HubWinWrap = styled(StyledContainer)`
    padding: 0 24px;
`

const HubTitleWrap = styled(Row)`
    span {
        position: relative;
        left: -16px;

        font-size: 30px;
        line-height: 1;
        font-weight: 500;
        color: #ffffff;
        transition: all linear 0.2s;
        padding-left: 52px;
    }

    ${IcnBackSvgWrap} {
        opacity: 1;
    }
`

const ScrollBoxWrap = styled(Column)`
    height: calc(100vh - 70px - 56px - 60px - 60px);
    overflow: auto;
`

const BlockCardWrap = styled(ColumnCenter)`
    width: calc((100vw - 48px - 16px) / 3 );
    height: calc((100vw - 48px - 16px) / 3 );
    background-color: #27069D;
    border-radius: 9px;
`

const Block2CardWrap = styled(StyledContainer)`
    width: 100%;
    padding: 24px;
    background-color: #27069D;
    border-radius: 9px;
    margin-bottom: 8px;
    box-sizing: border-box;
`

export default MobileNavCom