import { useCallback } from "react"

const useUnits = () => {
    const openBrowserByUrl = useCallback((url: string) => {
        (window as any).open(url, '_blank')
    }, [])

    const openBrowserByDownload = useCallback((label: string) => {
        let linkUrl = ''
        switch(label) {
            case 'dapp':
                linkUrl = 'https://dapp.chainge.finance/'
                break;
            case 'ios':
                linkUrl = 'https://apps.apple.com/us/app/chainge-finance/id1578987516'
                break
            default:
                linkUrl = 'https://play.google.com/store/apps/details?id=com.fusion.chainge.android&hl=en&gl=US'
        }
        (window as any).open(linkUrl, '_blank')
    }, [])

    const scrollTopByFlag = useCallback((flag: string) => {
        const eleObj = (window as any).document.querySelector(`#${flag}`)
        console.dir(eleObj)
        if(eleObj) {
            let offsetTop = ((eleObj.offsetTop || 0)- 98) as number
            (window as any).scrollTo({
                top: offsetTop,
                behavior: "smooth"
            });
        }
    }, [])

    const contactUs = useCallback((flag: string) => {
        let linkUrl = ''
        switch(flag) {
            case 'tg':
                linkUrl = 'https://t.me/chaingefinance'
                break;
            case 'twitter':
                linkUrl = 'https://twitter.com/FinanceChainge'
                break
            case 'medium':
                linkUrl = 'https://medium.com/@chainge-finance'
                break
            case 'youtube':
                linkUrl = 'https://www.youtube.com/@ChaingeFinance'
                break
            case 'linktree':
                linkUrl = 'https://linktr.ee/Chainge.Finance'
        }
        (window as any).open(linkUrl, '_blank')
    }, [])

    return {openBrowserByDownload, scrollTopByFlag, openBrowserByUrl, contactUs}
}

export default useUnits