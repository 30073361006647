import "./App.css";
import Routers from "../../router";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "style/preset";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/redux/hooks";
import { setIsMobile } from "hooks/redux/slices/appSlice";

const App = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    const handleResize = () => {
        const innerWidth = window.innerWidth
        if(innerWidth <= 1024) {
          dispatch(setIsMobile(true))
        } else {
          dispatch(setIsMobile(false))
        }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
}, [dispatch])

useEffect(() => {
  const innerWidth = window.innerWidth
  if(innerWidth <= 1024) {
    dispatch(setIsMobile(true))
  } else {
    dispatch(setIsMobile(false))
  }
}, [dispatch])

  return (
    <ThemeProvider theme={() => {
      return defaultTheme
    }}>
      <Routers></Routers>
    </ThemeProvider>
  );
};

export default App;
